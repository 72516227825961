import agentSmithApi from '@/state/agent-smith/agent-smith.slice'
import backendApi from '@/state/backend/backend.slice'
import billingApi from '@/state/billing/billing.slice'
import bookingApi from '@/state/booking/booking.slice'
import cypherApi from '@/state/cypher/cypher.slice'
import { useAppDispatch } from '@/state/helpers/useApp'
import inboxApi from '@/state/inbox/inbox.slice'
import integrationsApi from '@/state/integrations/integrations.slice'
import loyaltyApi from '@/state/loyalty/loyalty.slice'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import reportsApi from '@/state/reports/reports.slice'
import serviceApi from '@/state/service/service.slice'
import sites from '@/state/sites/sites.slice'
import tankApi from '@/state/tank/tank.slice'
import wifi from '@/state/wifi/wifi.slice'
import zionApi from '@/state/zion/zion.slice'
import {
  ArrowsRightLeftIcon,
  BackspaceIcon,
  TrashIcon,
} from '@heroicons/react/16/solid'
import {
  ButtonHTMLAttribDetailedHTMLProps,
  utes,
  ComponentProps,
  useEffect,
  useState,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  Modal,
  SButton,
  SIconButton,
  Tooltip,
  buttonVariants,
  cn,
  iconButtonVariants,
} from 'tailwind-ui'

const PinAuth = ({ onComplete }: { onComplete: () => void }) => {
  const { org_id: orgId } = useParams<{ org_id: string }>()
  const [pin, setPin] = useState('')
  const [authWithPin, { isLoading, error, isSuccess }] =
    morpheusApi.useGetAuthCodeUsingOrganisationPinMutation()
  const { pathname } = useLocation()

  const [generateToken, { isLoading: isGeneratingToken }] =
    backendApi.useLazyGetAuthWithCodeQuery()

  const handleKeypadPress = (num: number | string) => {
    if (num === 'backspace') {
      setPin((prev) => prev.slice(0, -1))
      return
    }

    if (pin.length < 4) {
      const newPin = pin + num
      setPin(newPin)

      if (newPin.length === 4) {
        //  handlePinComplete(newPin)
      }
    }
  }
  const dispatch = useAppDispatch()
  // Helper function to reset all API states in one call
  const resetAllApiStates = () => {
    /*
    dispatch(backendApi.util.resetApiState())
    dispatch(morpheusApi.util.resetApiState())
    dispatch(inboxApi.util.resetApiState())
    dispatch(serviceApi.util.resetApiState())
    dispatch(zionApi.util.resetApiState())
    dispatch(wifi.util.resetApiState())
    dispatch(loyaltyApi.util.resetApiState())
    dispatch(tankApi.util.resetApiState())
    dispatch(billingApi.util.resetApiState())
    dispatch(agentSmithApi.util.resetApiState())
    dispatch(bookingApi.util.resetApiState())
    dispatch(integrationsApi.util.resetApiState())
    dispatch(reportsApi.util.resetApiState())
    dispatch(sites.util.resetApiState())
    dispatch(cypherApi.util.resetApiState())
    */
    dispatch(
      morpheusApi.util.invalidateTags([
        {
          id: 'LIST',
          type: 'User',
        },
      ])
    )
  }

  // Set loading state
  const [isAuthenticating, setIsAuthenticating] = useState(false)

  // Main authentication handler
  const handlePinAuthentication = async (value: string) => {
    // Don't proceed if PIN is invalid
    if (value.length !== 4) return
    setIsAuthenticating(true)
    try {
      // Step 1: Authenticate with PIN
      const toastId = toast.loading('Verifying your PIN...')

      let responseRedirectUrl
      try {
        responseRedirectUrl = await authWithPin({
          orgId,
          pin: value,
          state: pathname,
        }).unwrap()

        toast.success('PIN verified', { id: toastId })
      } catch (error) {
        toast.error('Invalid PIN', { id: toastId })
        setPin('') // Clear PIN on error
        setIsAuthenticating(false)
        return // Exit early on auth failure
      }

      // Step 2: Extract code from redirect URL and generate token
      const url = new URL(responseRedirectUrl)
      const code = url.searchParams.get('code')
      const state = url.searchParams.get('state')

      if (!code) {
        toast.error('Authentication failed: Missing authorization code')
        setIsAuthenticating(false)
        return
      }

      // Step 3: Generate access token
      const tokenToastId = toast.loading('Generating access token...')

      try {
        await generateToken({ code }).unwrap()
        resetAllApiStates()
        toast.success('Successfully authenticated', { id: tokenToastId })
        onComplete() // Navigate or perform post-auth actions
      } catch (error) {
        toast.error('Failed to generate access token', { id: tokenToastId })
        // Consider implementing retry logic here
      }
    } catch (error) {
      // Handle unexpected errors
      toast.error('Authentication failed: Please try again')
      console.error('Auth flow error:', error)
    } finally {
      setIsAuthenticating(false)
    }
  }

  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="w-full">
        <div className="grid grid-cols-3 gap-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
            <button
              key={num}
              onClick={() => handleKeypadPress(num)}
              disabled={isLoading || isGeneratingToken || pin.length >= 4}
              className={cn(
                iconButtonVariants(),
                'aspect-square h-full size-full justify-center items-center text-xl font-bold'
              )}
            >
              {num}
            </button>
          ))}

          <button
            onClick={() => handleKeypadPress('backspace')}
            disabled={isLoading || isGeneratingToken || pin.length === 0}
            className={cn(
              iconButtonVariants(),
              'aspect-square h-full size-full justify-center items-center text-xl font-bold'
            )}
          >
            <BackspaceIcon className="h-6 w-6" />
          </button>

          <button
            onClick={() => handleKeypadPress(0)}
            disabled={isLoading || isGeneratingToken || pin.length >= 4}
            className={cn(
              iconButtonVariants(),
              'aspect-square h-full size-full justify-center items-center text-xl font-bold'
            )}
          >
            0
          </button>

          <div className="aspect-square"></div>
        </div>
      </div>
      <div className="">
        <InputOTP
          maxLength={4}
          pattern={'^[a-zA-Z0-9]+$'}
          id="enter_code"
          value={pin}
          disabled={isLoading || isGeneratingToken}
          onChange={(value) => {
            setPin(value)
          }}
          onComplete={handlePinAuthentication}
        >
          <InputOTPGroup>
            {Array.from({ length: 4 }).map((_, idx) => (
              <InputOTPSlot
                autoFocus={idx === 0}
                key={idx}
                index={idx}
                className="size-16 text-xl font-bold bg-neutral-100 border-neutral-200 dark:border-neutral-800 dark:bg-neutral-900 first:rounded-l-md last:rounded-r-md"
              />
            ))}
          </InputOTPGroup>
        </InputOTP>
      </div>
    </div>
  )
}

export const SetPinAuth = ({ user_id }: { user_id: string }) => {
  const { org_id: orgId } = useParams<{ org_id: string }>()

  const { data: userPin = null, isSuccess } =
    morpheusApi.useGetOrganisationUserPinQuery(
      {
        orgId,
        uid: user_id,
      },
      {
        skip: !user_id,
      }
    )

  const [updateUserPin, { isLoading: isUpdatingPin }] =
    morpheusApi.useUpdateOrganisationUserPinMutation()

  const [deleteUserPin, { isLoading: isDeletingPin }] =
    morpheusApi.useDeleteOrganisationUserPinMutation()

  const [pin, setPin] = useState(userPin?.pin ?? '')

  useEffect(() => {
    setPin(userPin?.pin ?? '')
  }, [userPin])

  const [hasPin, setHasPin] = useState(!!userPin)

  useEffect(() => {
    setHasPin(!!userPin)
  }, [userPin])

  return (
    <div className="flex gap-1">
      {hasPin ? (
        <>
          <InputOTP
            maxLength={4}
            pattern={'^[a-zA-Z0-9]+$'}
            id="enter_code"
            value={pin}
            onChange={(value) => {
              setPin(value)
            }}
          >
            <InputOTPGroup>
              {Array.from({ length: 4 }).map((_, idx) => (
                <InputOTPSlot
                  key={idx}
                  index={idx}
                  className="h-10 w-10 bg-neutral-100 border-neutral-200 dark:border-neutral-800 dark:bg-neutral-900 first:rounded-l-md last:rounded-r-md"
                />
              ))}
            </InputOTPGroup>
          </InputOTP>
          <div className="flex gap-1 items-center">
            <SButton
              className="h-full rounded-md size-full"
              variant={pin.length === 4 ? 'primary' : 'default'}
              disabled={pin.length !== 4}
              isLoading={isUpdatingPin}
              onClick={() => {
                toast.promise(
                  updateUserPin({
                    orgId,
                    uid: user_id,
                    pin,
                  }).unwrap(),
                  {
                    loading: 'Updating pin',
                    success: 'Pin updated',
                    error: 'Error updating pin',
                  }
                )
              }}
            >
              {isSuccess ? 'Update' : 'Create'}
            </SButton>
            {isSuccess ? (
              <SIconButton
                icon={TrashIcon}
                className="!rounded-md"
                onClick={() => {
                  toast.promise(
                    deleteUserPin({ orgId, uid: user_id }).unwrap(),
                    {
                      loading: 'Deleting pin',
                      success: 'Pin deleted',
                      error: 'Error deleting pin',
                    }
                  )
                }}
              />
            ) : null}
          </div>
        </>
      ) : (
        <SButton
          className="h-full rounded-md"
          onClick={() => {
            setHasPin(true)
          }}
        >
          Set account pin
        </SButton>
      )}
    </div>
  )
}

export const AuthWithPinButton = (
  rest: Omit<ComponentProps<typeof SIconButton>, 'onClick' | 'icon'>
) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        classNames={{
          container: 'md:items-center',
        }}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <PinAuth onComplete={() => setIsOpen(false)} />
        </div>
      </Modal>
      <Tooltip information="Authenticate with pin">
        <SIconButton
          onClick={() => setIsOpen(true)}
          icon={ArrowsRightLeftIcon}
          {...rest}
        />
      </Tooltip>
    </>
  )
}

export const AuthWithPinSimpleButton = (
  rest: Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'onClick' | 'icon'
  >
) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        classNames={{
          container: 'md:items-center',
        }}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <PinAuth onComplete={() => setIsOpen(false)} />
        </div>
      </Modal>
      <Tooltip information="Switch user using pin">
        <button onClick={() => setIsOpen(true)} {...rest} />
      </Tooltip>
    </>
  )
}

export default PinAuth
