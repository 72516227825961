import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSegment } from '@/connect-context/tracking/useSegment'

declare global {
  interface Window {
    Intercom: any
    FS: any
    advocately: any
  }
}

export const whenAvailable = (name: string, callback: any): void => {
  const interval = 300 // ms

  window.setTimeout(() => {
    if (window[name as keyof Window]) {
      return callback(window[name as keyof Window])
    }

    whenAvailable(name, callback)
  }, interval)
}

export const usePageViewLogger = (): any => {
  const { pathname } = useLocation()
  const segment = useSegment()
  //segment?.track('Page Viewed', {})
  useEffect(() => {
    if (!segment) return
    setTimeout(() => {
      console.log({ segment }, 'page-track')
      // segment.track('page.viewed')
      segment.page(
        import.meta.env.DEV
          ? 'product.stage.stampede.ai'
          : 'product.stampede.ai'
      )
      //  segment.screen('product.stampede.ai', pathname)
    }, 500)
  }, [pathname, segment])

  return null
}
