import Pusher, { type Channel } from 'pusher-js'
import { useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import config from '@/config'
import { getToken } from '@/utils/AuthTokenHelper'

// Cache Pusher instances by org ID
const pusherClients: Record<string, Pusher> = {}

function createPusherClient(orgId: string) {
  return new Pusher(config.sockets.pusher_client_key, {
    channelAuthorization: {
      transport: 'ajax',
      endpoint: `${config.url.morpheus}/organisations/${orgId}/pusher/auth`,
      headers: {
        Authorization: `Bearer ${getToken()?.accessToken}`,
      },
    },
    cluster: 'eu',
  })
}

/**
 * Returns a stable Pusher client instance for the given orgId.
 * If one doesn't exist, it creates & caches it.
 */
const usePusherClient = (orgId: string): Pusher | null => {
  return useMemo(() => {
    if (!orgId) return null
    if (pusherClients[orgId]) {
      return pusherClients[orgId]
    }
    const client = createPusherClient(orgId)
    pusherClients[orgId] = client

    return client
  }, [orgId])
}

/** Cache channels by org ID so each org has its own channel */
const cachedChannels: Record<string, Channel> = {}

/** Track how many components are subscribed to each topic */
const topicSubscriptions: Record<string, number> = {}

/**
 * Subscribes to a single topic (event name) on the "private-{orgId}" channel.
 */
export function usePusherSubscription<T extends object>(
  topic: string,
  callback: (data: T) => void
) {
  const { org_id } = useParams<{ org_id: string }>()
  const pusherClient = usePusherClient(org_id || '')
  // 1) Use a ref so we always have the latest callback without re-running the subscription effect
  const callbackRef = useRef(callback)

  // 2) Update the ref on every render if callback changes
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // 3) Subscription effect depends only on stable values: pusherClient, org_id, topic
  useEffect(() => {
    // If we have no org_id or no topic or no client, do nothing
    if (!pusherClient || !org_id || !topic) return

    // Ensure we have a channel for this org
    if (!cachedChannels[org_id]) {
      console.log('Subscribing to channel:', `private-${org_id}`)
      cachedChannels[org_id] = pusherClient.subscribe(`private-${org_id}`)
    }
    const channel = cachedChannels[org_id]

    // Increment subscription count for this topic
    topicSubscriptions[topic] = (topicSubscriptions[topic] || 0) + 1

    // Bind the event if this is the FIRST subscription for that topic
    if (topicSubscriptions[topic] === 1) {
      console.log('Binding topic:', topic)
      channel.bind(topic, (data: T) => {
        // Always use the latest callback
        callbackRef.current(data)
      })
    }

    // Cleanup: unbind only if no more subscriptions remain
    return () => {
      topicSubscriptions[topic]--
      if (topicSubscriptions[topic] === 0) {
        console.log('Unbinding topic:', topic)
        channel.unbind(topic)
        delete topicSubscriptions[topic]
      }
    }
  }, [pusherClient, org_id, topic])
}

export default createPusherClient
