import { Fragment } from 'react'
import { useMatches } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  cn,
} from 'tailwind-ui'

export function Breadcrumbs({ isSlim = false }: { isSlim?: boolean }) {
  const matches = useMatches()
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match))

  if (crumbs.length === 0) {
    return null
  }

  return (
    <Breadcrumb className="inline-flex  flex-wrap   h-full rounded-full">
      <BreadcrumbList
        className={cn('flex-wrap text-ellipsis ', {
          'gap-0 sm:gap-0': !isSlim,
          'gap-0.5 sm:gap-0.5': isSlim,
        })}
      >
        {crumbs.map((crumb, index) => (
          <Fragment key={index}>
            <BreadcrumbItem
              className={cn(
                'truncate   rounded-full max-w-48 text-neutral-800 dark:text-neutral-200  ',
                {
                  'font-semibold px-4 h-10 hover:bg-neutral-200 dark:hover:bg-neutral-800':
                    !isSlim,
                  '': isSlim,
                }
              )}
            >
              {crumb}
            </BreadcrumbItem>
            {index < crumbs.length - 1 && <BreadcrumbSeparator />}
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
