import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { toast } from 'sonner'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import { retry, createApi } from '@reduxjs/toolkit/query/react'
import isEqual from 'lodash/isEqual'
import { endOfDay, startOfDay } from 'date-fns'
import {
  type ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
} from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'
import { buildUrl } from '@/utils/common'
import {
  type ReviewSentimentType,
  type UserReviewPlatforms,
} from '@/connect-types/reviews/reviews.type'
import config from '@/config'
import { getToken } from '@/utils/AuthTokenHelper'
import { shouldBailout } from '../helpers'
import {
  type ReviewResponseType,
  type ReviewPageType,
  type SimpleReviewReportType,
  type ReviewReportType,
} from '../morpheus/types/reviews'
import { type CustomPromptType, type CustomCopyType } from './tank.types'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (url.startsWith('https://')) {
        axiosUrl = url
      }

      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        err.response?.status !== 429 &&
        !url.includes('segment')
      ) {
        ServiceErrorHandler(err)
      }

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const tankApi = createApi({
  reducerPath: 'tank',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.tank }), {
    maxRetries: 2,
  }),
  tagTypes: [
    'ReviewPage',
    'ReviewResponses',
    'GoogleConnection',
    'GoogleAccount',
    'Prompts',
    'CustomCopy',
  ],
  endpoints: (build) => ({
    getOauthToken: build.query<
      { success: boolean; error?: string; details?: unknown },
      { code: string; oauth_uid: string; state: string }
    >({
      query: ({ code, oauth_uid, state }) => ({
        method: 'GET',
        url: `/oauth/token`,
        params: { code, oauth_uid, state },
      }),
    }),
    deleteOauthToken: build.mutation<
      { success: boolean; error?: string; details?: unknown },
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        method: 'DELETE',
        url: `/oauth/token/${orgId}`,
      }),
    }),
    getGoogleConnection: build.query<
      { isConnected: boolean },
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/google/connection`,
        method: 'GET',
      }),
      providesTags: (_result, _error, params) => [
        { type: 'GoogleConnection', id: params.orgId },
      ],
    }),
    getGoogleAccount: build.query<
      { accounts: unknown[] },
      { orgId: string; sync?: boolean }
    >({
      query: ({ orgId, sync }) => ({
        url: `/${orgId}/google/accounts`,
        method: 'GET',
        params: sync ? { sync: true } : undefined,
      }),
      providesTags: (_result, _error, params) => [
        { type: 'GoogleAccount', id: params.orgId },
      ],
    }),
    verifyGoogleAccount: build.mutation<
      unknown,
      { orgId: string; account_id: string }
    >({
      query: ({ orgId, account_id }) => ({
        url: `/${orgId}/google/accounts/${account_id}/verify`,
        method: 'PUT',
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: 'GoogleAccount', id: params.orgId },
      ],
    }),
    deleteGoogleAccount: build.mutation<
      unknown,
      { orgId: string; account_id: string }
    >({
      query: ({ orgId, account_id }) => ({
        url: `/${orgId}/google/accounts/${account_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: 'GoogleAccount', id: params.orgId },
      ],
    }),
    getGoogleLocations: build.query<
      { locations: unknown[] },
      { orgId: string; account_id: string }
    >({
      query: ({ orgId, account_id }) => ({
        url: `/${orgId}/google/accounts/${account_id}/locations`,
        method: 'GET',
      }),
      providesTags: (_result, _error, params) => [
        { type: 'GoogleAccount', id: params.orgId },
      ],
    }),
    getGoogleLocation: build.query<
      unknown,
      { orgId: string; account_id: string; location_id: string }
    >({
      query: ({ orgId, account_id, location_id }) => ({
        url: `/${orgId}/google/accounts/${account_id}/locations/${location_id}`,
        method: 'GET',
      }),
    }),
    batchLinkGoogleLocations: build.mutation<
      { success: boolean },
      {
        orgId: string
        account_id: string
        locations: { location_id: string; review_settings_id: string }[]
      }
    >({
      query: ({ orgId, account_id, locations }) => ({
        url: `/${orgId}/google/accounts/${account_id}/locations/batch-link`,
        method: 'POST',
        data: { data: locations },
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: 'GoogleAccount', id: params.orgId },
      ],
    }),
    putGoogleReviewReply: build.mutation<
      unknown,
      {
        orgId: string
        location_id: string
        account_id: string
        review_id: string
        tank_review_id: string
        reply: string
      }
    >({
      query: ({
        orgId,
        location_id,
        account_id,
        review_id,
        tank_review_id,
        reply,
      }) => ({
        url: `/${orgId}/google/accounts/${account_id}/locations/${location_id}/reviews/${review_id}/reply`,
        method: 'PUT',
        data: { comment: reply },
        params: { id: tank_review_id },
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: 'ReviewResponses', id: params.tank_review_id },
      ],
    }),
    delGoogleReviewReply: build.mutation<
      ReviewResponseType,
      {
        orgId: string
        location_id: string
        account_id: string
        review_id: string
      }
    >({
      query: ({ orgId, location_id, account_id, review_id }) => ({
        url: `/${orgId}/google/accounts/${account_id}/locations/${location_id}/reviews/${review_id}/reply`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => [
        { type: 'ReviewResponses', id: params.tank_review_id },
      ],
    }),

    generateGoogleReviewReply: build.mutation<
      { completion: string },
      {
        orgId: string
        review_id: string
        review_text: string
        review_rating: string
        reviewer_display_name?: string
        review_settings_id?: string
        google_location_name?: string
      }
    >({
      query: ({
        orgId,
        review_id,
        review_text,
        review_rating,
        reviewer_display_name,
        review_settings_id,
        google_location_name,
      }) => ({
        url: `/${orgId}/reviews/${review_id}/reviews/generate-response`,
        method: 'GET',
        params: {
          review_text,
          review_rating,
          reviewer_display_name,
          review_settings_id,
          google_location_name,
        },
      }),
    }),

    streamGoogleReviewReply: build.mutation<
      { completion: string },
      {
        orgId: string
        review_id: string
        review_text: string
        review_rating: string
        reviewer_display_name?: string
        review_settings_id?: string
        google_location_name?: string
        custom_prompt_id?: string
        test_prompt?: string
        onChunk?: (chunk: string) => void
      }
    >({
      queryFn: async (args, _api, _extraOptions, baseQuery) => {
        const { onChunk, ...queryArgs } = args

        try {
          const body = {
            review_text: queryArgs.review_text ?? '',
            ...(queryArgs.review_rating && {
              review_rating: queryArgs.review_rating,
            }),
            ...(queryArgs.reviewer_display_name && {
              reviewer_display_name: queryArgs.reviewer_display_name,
            }),
            ...(queryArgs.review_settings_id && {
              review_settings_id: queryArgs.review_settings_id,
            }),
            ...(queryArgs.google_location_name && {
              google_location_name: queryArgs.google_location_name,
            }),
            ...(queryArgs.custom_prompt_id && {
              custom_prompt_id: queryArgs.custom_prompt_id,
            }),
            ...(queryArgs.test_prompt && {
              test_prompt: queryArgs.test_prompt,
            }),
          }

          const url = `/${queryArgs.orgId}/reviews/${queryArgs.review_id}/reviews/stream-response`

          const token = getToken()?.accessToken

          const response = await fetch(`${config.url.tank}${url}`, {
            method: 'POST',
            headers: {
              Accept: 'text/event-stream',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
          })

          if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`)
          }

          if (!response.body) {
            throw new Error('ReadableStream not supported')
          }

          const reader = response.body.getReader()
          const decoder = new TextDecoder()
          let fullText = ''

          while (true) {
            const { done, value } = await reader.read()
            if (done) break

            const chunk = decoder.decode(value, { stream: true })
            const lines = chunk.split('\n\n')

            for (const line of lines) {
              if (line.startsWith('data: ')) {
                try {
                  const jsonStr = line.substring(6)
                  const jsonData = JSON.parse(jsonStr) as string
                  fullText += jsonData || ''

                  if (onChunk && jsonData) {
                    onChunk(jsonData)
                  }
                } catch (e) {
                  console.error('Error parsing SSE data:', e)
                }
              }
            }
          }

          return { data: { completion: fullText } }
        } catch (error) {
          return {
            error: {
              status: 'ERROR',
              data: error instanceof Error ? error.message : 'Unknown error',
            },
          }
        }
      },
    }),
    getGoogleReviews: build.query<
      { reviews: unknown[] },
      { orgId: string; account_id: string; location_id: string }
    >({
      query: ({ orgId, account_id, location_id }) => ({
        url: `/${orgId}/google/accounts/${account_id}/locations/${location_id}/reviews`,
        method: 'GET',
      }),
    }),
    getGoogleReview: build.query<
      { review: unknown },
      {
        orgId: string
        account_id: string
        location_id: string
        review_id: string
      }
    >({
      query: ({ orgId, account_id, location_id, review_id }) => ({
        url: `/${orgId}/google/accounts/${account_id}/locations/${location_id}/reviews/${review_id}`,
        method: 'GET',
      }),
    }),

    getPlacesNearbySearch: build.query<
      { places: unknown[] },
      { orgId: string; latitude: number; longitude: number }
    >({
      query: ({ orgId, latitude, longitude }) => ({
        method: 'GET',
        url: `/${orgId}/google/places/nearby-search`,
        params: { latitude, longitude },
      }),
    }),

    getPlacesTextSearch: build.query<
      { places: unknown[] },
      { orgId: string; latitude: number; longitude: number; textQuery: string }
    >({
      query: ({ orgId, latitude, longitude, textQuery }) => ({
        method: 'GET',
        url: `/${orgId}/google/places/text-search`,
        params: { latitude, longitude, textQuery },
      }),
    }),

    getOrgReviewPages: build.query<ReviewPageType[], { orgId: string }>({
      query: ({ orgId }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/pages`, // branding_settings, template_id, deleted_at missing
      }),
      providesTags: (items = []) =>
        items.map((item) => ({ id: item.id, type: 'ReviewPage' })),
    }),

    deleteReviewPage: build.mutation<
      ReviewPageType,
      { orgId: string; id: string }
    >({
      query: ({ orgId, id }) => ({
        method: 'DELETE',
        url: `/${orgId}/reviews/pages/${id}`,
      }),
      transformResponse(item: ReviewPageType) {
        toast.success(`Review page deleted`)
        return item
      },
      invalidatesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
    }),

    getReviewsPage: build.query<ReviewPageType, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/pages/${id}`,
      }),
      providesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
    }),

    getVenueReviewsPage: build.query<
      ReviewPageType,
      { org_id: string; serial: string }
    >({
      query: ({ org_id, serial }) => ({
        method: 'GET',
        url: `/${org_id}/reviews/pages/venue/${serial}`,
      }),
      providesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
    }),

    updateReviewsPage: build.mutation<
      ReviewPageType,
      { orgId: string; page: ReviewPageType }
    >({
      query: ({ orgId, page }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/pages/${page.id}`,
        data: page,
      }),
      invalidatesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
      transformResponse(item: ReviewPageType) {
        toast.success('Review page updated')
        return item
      },
    }),

    createReviewsPage: build.mutation<
      ReviewPageType,
      { orgId: string; page: ReviewPageType }
    >({
      query: ({ orgId, page }) => ({
        method: 'POST',
        url: `/${orgId}/reviews/pages`,
        data: { ...page, org_id: orgId },
      }),
      invalidatesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
      transformResponse(item: ReviewPageType) {
        toast.success('Review page created')
        return item
      },
    }),

    updateReviewsPageBrand: build.mutation<
      unknown,
      { orgId: string; id: string; branding_id: string }
    >({
      query: ({ orgId, id, branding_id }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/pages/${id}/brand`,
        data: {
          branding_id,
        },
      }),
      invalidatesTags: (_1, _2, { id }) => [{ type: 'ReviewPage', id }],
      transformResponse(data) {
        toast.success('Brand kit updated')
        return data
      },
    }),

    getReviewResponses: build.query<
      ServiceCursorPaginatedResponseType<ReviewResponseType> & {
        redirectConversionContext:
          | {
              sentEmails: number
              redirectCounts: {
                total: number
                external: number
              }
              externalReviews: {
                total: number
                confirmed: number
              }
            }
          | {}
      },
      {
        orgId: string
        query: {
          review?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms[]
          review_sentiment?: ReviewSentimentType
          redirect_conversion?: string
          done?: boolean
          sort_by?: 'asc' | 'desc'
          limit: number
          cursor: string
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews`, query),
      }),
      providesTags: (items) => [
        ...(items?.data ?? []).map((item) => ({
          id: item.id,
          type: 'ReviewResponses' as const,
        })),
        { id: 'LIST', type: 'ReviewResponses' },
      ],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        console.log({ queryArgs, endpointName })
        return JSON.stringify({
          orgId: queryArgs.orgId,
          params: {
            ...queryArgs.query,
            cursor: '',
          },
        })
      },
      merge: (currentCache, newItems) => {
        currentCache.links = newItems.links
        currentCache.meta = newItems.meta

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })
      },
      forceRefetch({ currentArg, previousArg }) {
        console.log(
          { currentArg, previousArg },
          'queryArgs',
          isEqual(currentArg?.query, previousArg?.query)
        )
        return !isEqual(currentArg?.query, previousArg?.query)
        //  return currentArg !== previousArg
      },
    }),

    getReviewResponse: build.query<
      ReviewResponseType,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/${id}`,
      }),
      providesTags: (item) => [{ id: item?.id, type: 'ReviewResponses' }],
    }),

    updateReview: build.mutation<
      ReviewResponseType,
      {
        orgId: string
        id: string
        review: ReviewResponseType
      }
    >({
      query: ({ orgId, review, id }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/${id}`,
        data: { ...review },
      }),

      invalidatesTags: (item) => [{ id: item?.id, type: 'ReviewResponses' }],
    }),

    getPrompts: build.query<CustomPromptType[], { orgId: string }>({
      query: ({ orgId }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/prompts`,
      }),
      providesTags: (items = []) => [
        ...items.map((item) => ({ id: item.id, type: 'Prompts' as const })),
        { type: 'Prompts', id: 'LIST' },
      ],
    }),

    getPrompt: build.query<CustomPromptType, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/prompts/${id}`,
      }),
      providesTags: (item) => [{ type: 'Prompts', id: item?.id }],
    }),

    createPrompt: build.mutation<
      CustomPromptType,
      {
        orgId: string
        data: {
          name: string
          prompt: string
          is_org_default?: boolean
        }
      }
    >({
      query: ({ orgId, data }) => ({
        method: 'POST',
        url: `/${orgId}/reviews/prompts`,
        data,
      }),
      invalidatesTags: [{ type: 'Prompts', id: 'LIST' }],
    }),

    updatePrompt: build.mutation<
      CustomPromptType,
      {
        orgId: string
        id: string
        data: {
          name?: string
          prompt?: string
          is_org_default?: boolean
        }
      }
    >({
      query: ({ orgId, id, data }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/prompts/${id}`,
        data,
      }),
      invalidatesTags: (item) => [
        { type: 'Prompts', id: item?.id },
        { type: 'Prompts', id: 'LIST' },
      ],
    }),

    deletePrompt: build.mutation<
      CustomPromptType,
      { orgId: string; id: string }
    >({
      query: ({ orgId, id }) => ({
        method: 'DELETE',
        url: `/${orgId}/reviews/prompts/${id}`,
      }),
      invalidatesTags: (item) => [
        { type: 'Prompts', id: item?.id },
        { type: 'Prompts', id: 'LIST' },
      ],
    }),

    getCustomCopySets: build.query<CustomCopyType[], { orgId: string }>({
      query: ({ orgId }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/custom-copy`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CustomCopy' as const, id })),
              { type: 'CustomCopy', id: 'LIST' },
            ]
          : [{ type: 'CustomCopy', id: 'LIST' }],
    }),

    getCustomCopySet: build.query<
      CustomCopyType,
      { orgId: string; id: string }
    >({
      query: ({ orgId, id }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/custom-copy/${id}`,
      }),
      providesTags: (result) => [{ type: 'CustomCopy', id: result?.id }],
    }),

    createCustomCopySet: build.mutation<
      CustomCopyType,
      {
        orgId: string
        data: {
          name: string
          feedback_placeholder: string
          rating_1: string
          rating_2: string
          rating_3: string
          rating_4: string
          rating_5: string
        }
      }
    >({
      query: ({ orgId, data }) => ({
        method: 'POST',
        url: `/${orgId}/reviews/custom-copy`,
        data,
      }),
      invalidatesTags: [{ type: 'CustomCopy', id: 'LIST' }],
    }),

    updateCustomCopySet: build.mutation<
      CustomCopyType,
      {
        orgId: string
        id: string
        data: {
          name?: string
          feedback_placeholder?: string
          rating_1?: string
          rating_2?: string
          rating_3?: string
          rating_4?: string
          rating_5?: string
        }
      }
    >({
      query: ({ orgId, id, data }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/custom-copy/${id}`,
        data,
      }),
      invalidatesTags: (result) => [
        { type: 'CustomCopy', id: result?.id },
        { type: 'CustomCopy', id: 'LIST' },
      ],
    }),

    deleteCustomCopySet: build.mutation<void, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        method: 'DELETE',
        url: `/${orgId}/reviews/custom-copy/${id}`,
      }),
      invalidatesTags: [{ type: 'CustomCopy', id: 'LIST' }],
    }),

    changePageCustomCopySet: build.mutation<
      ReviewPageType,
      {
        orgId: string
        pageId: string
        data: {
          custom_copy_id: string
        }
      }
    >({
      query: ({ orgId, pageId, data }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/pages/${pageId}/custom-copy`,
        data,
      }),
      invalidatesTags: (result) => [
        { type: 'ReviewPage', id: result?.id },
        { type: 'ReviewPage', id: 'LIST' },
        { type: 'CustomCopy', id: 'LIST' },
      ],
    }),

    refreshPageFrontend: build.mutation<
      ReviewPageType,
      { orgId: string; pageId: string }
    >({
      query: ({ orgId, pageId }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/pages/${pageId}/refresh`,
      }),
      invalidatesTags: (result) => [
        { type: 'ReviewPage', id: result?.id },
        { type: 'ReviewPage', id: 'LIST' },
      ],
    }),

    sendPreview: build.mutation<
      { message: string },
      {
        orgId: string
        id: string
        data: {
          email: string
          name: string
        }
      }
    >({
      query: ({ orgId, id, data }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/${id}/send/preview`,
        data,
      }),
      transformResponse(
        { message: resMessage }: { message: string },
        _1,
        { data }
      ) {
        toast.success(`Preview sent to ${data.email}`)
        return { message: resMessage }
      },
    }),

    downloadResponses: build.query<
      unknown,
      {
        orgId: string
        query: {
          review_text?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms
          review_sentiment?: ReviewSentimentType
          done?: boolean
          sort_by?: 'asc' | 'desc'
          limit: number
          cursor: string
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/download`, query),
      }),
      transformResponse(data: string, _1, { orgId }) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `stampede_${orgId}_review_data.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
    }),

    getSimpleReports: build.query<
      SimpleReviewReportType,
      {
        orgId: string
        query: {
          review?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms
          review_sentiment?: ReviewSentimentType
          done?: boolean
          start: Date
          end: Date
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/simple`, {
          ...query,
          start: startOfDay(query.start),
          end: endOfDay(query.end),
        }),
      }),
    }),

    getReports: build.query<
      ReviewReportType,
      {
        orgId: string
        query: {
          review?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms
          review_sentiment?: ReviewSentimentType
          done?: boolean
          sort?: 'asc' | 'desc'
          groupBy?: 'month' | 'week' | 'day' | 'hour'
          limit?: number
          offset?: number
          start: Date
          end: Date
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/grouped`, {
          ...query,
          start: startOfDay(query.start),
          end: endOfDay(query.end),
        }),
      }),
    }),

    getEmailReports: build.query<
      {
        sentEmails: number
        totals: {
          sentEmails: number
          redirects: number
          externalRedirects: number
          externalRedirectsPercentage: number
          externalCompleted: number
          externalCompletedPercentage: number
          viaStampedeExternalCompleted: number
          viaStampedeExternalCompletedPercentage: number
        }
        redirects: { count: number; rating: number; percentage: number }[]
        chart: []
      },
      {
        orgId: string
        query: {
          serial?: string
          limit?: number
          offset?: number
          review_settings_ids: string[]
          start: Date
          end: Date
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/emails`, {
          ...query,
          start: startOfDay(query.start),
          end: endOfDay(query.end),
        }),
      }),
    }),

    sendFeatureRequest: build.mutation<
      unknown,
      {
        org_id: string
        data: {
          name: string
          comment: string
          user: {
            email: string
            first: string
            last: string
            uid: string
            company: string
          }
        }
      }
    >({
      query: ({ org_id, data }) => ({
        method: 'POST',
        url: `/${org_id}/reviews/feature-comments`,
        data,
      }),
    }),
  }),
})

export default tankApi
