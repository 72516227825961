import { useState, useEffect, useLayoutEffect } from 'react'
import { Theme, useColorMode as useLegacyColorMode } from '@chakra-ui/react'
import Cookies from 'js-cookie'

const applyMetaColor = (color: string) => {
  const metas = document.getElementsByTagName('meta')
  for (let i = 0; i < metas.length; i += 1) {
    if (metas.item(i).name === 'theme-color') {
      metas.item(i).content = color
    }
  }
}

const applyTheme = (isDark: boolean) => {
  const systemIsDark = window.matchMedia('(prefers-color-scheme: dark)').matches
  console.info('systemIsDark applied theme', systemIsDark, isDark)
  if (systemIsDark) {
    isDark = true
  } else {
    isDark = false
  }
  const theme = isDark ? 'dark' : 'light'
  const root = document.documentElement
  const body = document.body

  // Set cookie with proper attributes
  document.cookie = `theme=${theme}; path=/; max-age=31536000; SameSite=Lax`

  // Handle classList in a more reliable way
  if (isDark) {
    root.classList.add('dark')
    body.classList.add('dark')
    body.classList.add('chakra-ui-dark')
    body.classList.remove('chakra-ui-light')
  } else {
    root.classList.remove('dark')
    body.classList.remove('dark')
    body.classList.remove('chakra-ui-dark')
    body.classList.add('chakra-ui-light')
  }

  // Set consistent theme attributes
  root.style.colorScheme = theme
  root.dataset.theme = theme
  body.dataset.theme = theme

  applyMetaColor(isDark ? '#000000' : '#FFFFFF')

  console.info(
    'applied theme',
    theme,
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )
}

const THEME_COOKIE = 'theme'
const DARK_CLASS = 'dark'
const COLORS = {
  dark: '#000000',
  light: '#FFFFFF',
} as const

const getThemeFromCookie = () => {
  const cookies = document.cookie.split(';')
  const themeCookie = cookies
    .find((cookie) => cookie.trim().startsWith('theme='))
    ?.split('=')[1]

  return themeCookie === 'dark' ? 'dark' : 'light'
}

export const useColorMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const cookieTheme = getThemeFromCookie()
    // If no valid cookie theme, use system preference
    if (cookieTheme === 'dark' || cookieTheme === 'light') {
      return cookieTheme === 'dark'
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  })

  useEffect(() => {
    applyTheme(isDarkMode)
  }, [isDarkMode])

  // System theme change detection
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    const handleChange = (e: MediaQueryListEvent) => {
      // Only update if no cookie exists (user hasn't set preference)

      setIsDarkMode(e.matches)
    }

    mediaQuery.addEventListener('change', handleChange)
    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [])

  return [isDarkMode, setIsDarkMode] as const
}
