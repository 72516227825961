import { toast } from 'sonner'
import config from '../config'
import { whenAvailable } from './tracking'

declare global {
  interface Window {
    Chargebee: any
  }
}

whenAvailable('Chargebee', (Chargebee: any) => {
  return Chargebee.init({
    site: config.chargebee.site,
  })
})

export const setSession = (portalData): unknown =>
  chargebeeInstance().setPortalSession(portalData)

const chargebeeInstance = () => window.Chargebee.getInstance()

const callbacks = {
  close() {
    /**
     * maybe push to saga on close
     */
  },
  paymentSourceAdd() {
    // Optional
    // called whenever a new payment source is added in portal
    chargebeeInstance().closeAll()
    toast.success('Payment method added')
  },
  paymentSourceRemove() {
    toast.success('Payment method removed')
  },
}

const portal = () => chargebeeInstance().createChargebeePortal()

export const addPaymentMethod = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
  })

export const addAddress = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().ADDRESS,
  })

export const billingHistory = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
  })

export const addAccountDetails = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().ACCOUNT_DETAILS,
  })

export const openBilling = () => portal().open(callbacks)
