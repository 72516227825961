import { ChevronRightIcon, ShareIcon } from '@heroicons/react/16/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import {
  FadeInBelowOutAbove,
  Input,
  Modal,
  SButton,
  SIconButton,
  type SIconButtonProps,
  Select,
  cn,
} from 'tailwind-ui'
import { z } from 'zod'
import { copyToClipboard } from '@/utils/copy'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { type OrganisationUsersAccess } from '@/state/auth/auth.types'
import useUser, { useUserRole } from '@/hooks/useUser'
import { hookFormErrorToast } from '@/helpers/zod'
import config from '@/config'
import LocationSelect from '@/common/Form/LocationSelect'

const initialState = {
  isOpen: false,
  onClose: () => {},
  onOpen: ({ role }: { role?: string }) => {},
  serials: [],
}

const UserInviteContext = createContext(initialState)

export function UserInviteProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)
  const { org_id } = useParams<{ org_id: string }>()
  const [search] = useSearchParams()
  const { data: roles = [] } = morpheusApi.useGetOrganisationUserRolesQuery(
    {
      orgId: org_id!,
    },
    {
      skip: !isOpen,
    }
  )
  const { data: users = [] } = morpheusApi.useGetOrganisationUsersQuery(
    {
      orgId: org_id!,
    },
    {
      skip: !isOpen,
    }
  )
  const { serial } = useParams<{ serial: string }>()
  const { pathname } = useLocation()
  const initialValues = useMemo(
    () => ({
      serials: serial ? [serial] : [],
      email: '',
      success_link: pathname.includes('organisation/team')
        ? null
        : `${config.url.connect}${pathname}${search.toString() ? `?${search.toString()}` : ''}`,
      role: 'admin',
    }),
    [serial, pathname]
  )

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isValid },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    delayError: 300,
    defaultValues: initialValues,
    resolver: zodResolver(
      z.object({
        serials: z.array(z.string().length(12)).default([]),
        email: z.string().email(),
        role: z.enum([
          'admin',
          'moderator',
          'reports',
          'booking',
          'marketeer',
          'front-of-house',
          'it-support',
        ]),
      })
    ),
  })

  useEffect(() => {
    reset(initialValues)
  }, [])

  const [invite, { isLoading }] = morpheusApi.useInviteMutation()
  const [inviteId, setInviteId] = useState('')
  const onSubmit = () => {
    const values = getValues()
    return toast.promise(invite({ orgId: org_id, data: values }).unwrap(), {
      loading: `Sending invite`,
      success: (data) => {
        reset(initialValues)
        // setIsOpen(false)
        setInviteId(data.id)
        return `Invite sent`
      },
      error: `Error sending invite`,
    })
  }

  const serials = watch('serials')
  const email = watch('email')
  const user = useUser()
  const role = useUserRole()

  const filteredUsers = useMemo(() => {
    return users.filter((item) =>
      item.oauth_users.email
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(email.toLowerCase().replace(/\s+/g, ''))
    )
  }, [users, email])

  const [selectedUser, setSelectedUser] =
    useState<OrganisationUsersAccess | null>(null)

  return (
    <UserInviteContext.Provider
      value={{
        isOpen,
        onClose: () => {
          setIsOpen(false)
        },
        onOpen: ({ role }: { role?: string }) => {
          setTimeout(() => {
            setValue('role', role ?? 'admin')
          }, 1000)
          setIsOpen(true)
        },
      }}
    >
      <Modal
        title="Share With Team"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        classNames={{
          body: '!p-0',
        }}
      >
        {user ? (
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit, hookFormErrorToast)}
            className="flex flex-col gap-3 py-4"
          >
            {pathname.replace(`/${org_id}`, '') && (
              <div className="w-full px-6 pb-4 border-b ">
                <div>
                  <p className="text-sm text-gray-400">
                    {pathname.replace(`/${org_id}`, '')}
                    {search.toString() ? `?${search.toString()}` : ''}
                  </p>
                </div>
              </div>
            )}
            <div className="grid grid-cols-6 gap-3 px-6">
              <div className="col-span-4">
                <label
                  htmlFor="guest_invite"
                  className="block text-sm font-medium leading-6"
                >
                  Invite Email
                </label>
                <Input
                  type="email"
                  id="guest_invite"
                  value={email}
                  onChange={(e) => {
                    setValue('email', e.target.value)
                  }}
                  variant="flushed"
                />
              </div>
              {role ? (
                <div className="col-span-2">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6"
                  >
                    Role
                  </label>
                  <Select
                    variant="flushed"
                    id="role"
                    {...register('role')}
                    className="capitalize"
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    {roles
                      .filter((item) => {
                        if (role.legacy_id === 0) {
                          return item.legacy_id >= 2
                        }
                        if (role.legacy_id === 1) {
                          return item.legacy_id >= 2
                        }
                        return item.legacy_id >= role.legacy_id
                      })
                      .map((item) => (
                        <option key={item.id} value={item.key}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                </div>
              ) : null}
              <div className="col-span-6">
                <div className="flex justify-between">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6"
                  >
                    Access to venues
                  </label>
                </div>

                <LocationSelect
                  resetText="Can access all locations"
                  size="md"
                  mode="multiple"
                  value={serials}
                  onChange={(v: string[]) => {
                    console.log({ allSerials: v })
                    const hasNullValue = v.filter((item) => !item)
                    setValue('serials', hasNullValue.length >= 1 ? [] : v)
                  }}
                />
              </div>
            </div>

            <FadeInBelowOutAbove show={isValid}>
              <div className="bg-white dark:bg-black border-t border-b py-3 px-6">
                <p className="text-gray-500 text-sm">
                  An email will be sent to (<strong>{email}</strong>) with a
                  link to create their account, once they complete this step we
                  will send you an email to (<strong>{user.email}</strong>)
                  letting you know.
                </p>
              </div>
            </FadeInBelowOutAbove>
            <div className="self-end pt-1 px-6">
              <SButton
                rightIcon={ChevronRightIcon}
                type="submit"
                isLoading={isSubmitting || isLoading}
              >
                Invite Product User
              </SButton>
            </div>

            <FadeInBelowOutAbove show={Boolean(inviteId)}>
              <div className="bg-white dark:bg-black border-t border-b py-3 px-6 flex gap-3">
                <p className="text-gray-500 text-xs">
                  {`${config.url.connect}/account-invite/${inviteId}/accept`}
                </p>
                <SButton
                  onClick={() => {
                    copyToClipboard(
                      `${config.url.connect}/account-invite/${inviteId}/accept`
                    )
                  }}
                >
                  Copy
                </SButton>
              </div>
            </FadeInBelowOutAbove>
          </form>
        ) : null}
      </Modal>

      {children}
    </UserInviteContext.Provider>
  )
}

export const useUserInvite = () => {
  return useContext(UserInviteContext)
}

export const ShareButton: React.ForwardRefExoticComponent<
  SIconButtonProps & React.RefAttributes<HTMLButtonElement>
> = (props) => {
  const { onOpen } = useUserInvite()
  return (
    <SIconButton
      aria-label="share-thread"
      icon={ShareIcon}
      onClick={onOpen}
      variant="ghost_default"
      isRound
      {...props}
      className={cn(props.className)}
    />
  )
}
