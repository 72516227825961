import type { Analytics } from '@segment/analytics-next'
import { AnalyticsBrowser } from '@segment/analytics-next'
import {
  createContext,
  type FunctionComponent,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import config from '@/config'

export const SegmentContext = createContext<Analytics | undefined>(undefined)

const SegmentProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)

  useEffect(() => {
    const loadAnalytics = async () => {
      if (!config.prod) return

      try {
        const [response] = await AnalyticsBrowser.load({
          writeKey: 'u2GHkUwvxg0vUTLMWiwxzPafW29rGs5P',
        })
        setAnalytics(response)
      } catch (e) {
        console.error(e)
      }
    }

    loadAnalytics()
  }, [])

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  )
}

export default SegmentProvider
