// useGoBack.ts
import { useNavigate, useLocation, To } from 'react-router-dom'
import { useHistoryContext } from './history-provider'
export interface UseGoBackOptions {
  /**
   * Optional override: if provided, the back button always navigates to this route.
   */
  backTo?: To
}

/**
 * Determines the “branch base” from the URL’s segments.
 *
 * We assume the first segment is the account ID.
 * For most sections, the branch base is the first two segments.
 * For "venues" pages (which follow the pattern
 *   /account/venues/:venueId/bookings/...
 * )
 * the branch base is the first four segments.
 */
function getBranchBase(segments: string[]): string {
  if (segments.length >= 2) {
    if (segments[1].toLowerCase() === 'venues' && segments.length >= 4) {
      // e.g. /account/venues/venueId/bookings
      return '/' + segments.slice(0, 4).join('/')
    }
    // Default branch base is the first two segments.
    return '/' + segments.slice(0, 2).join('/')
  }
  return '/'
}

/**
 * Computes the proper “back” route based solely on the URL structure and navigation history.
 *
 * 1. Split the current path into segments.
 * 2. Compute the immediate parent (by removing the last segment).
 * 3. Compute the branch base (using getBranchBase).
 * 4. Check the global history:
 *    - If the immediately previous route exists and its branch base (as computed from its segments)
 *      is different from the current route’s branch base, then return that previous route.
 * 5. Otherwise, if the immediate parent is deeper than the branch base (i.e. the user is nested),
 *    then return the branch base (jumping all the way back).
 * 6. Fallback to the immediate parent.
 */
function getBackRoute(currentPath: string, history: string[]): string {
  const segments = currentPath.split('/').filter(Boolean)
  const currentDepth = segments.length
  const immediateParent =
    currentDepth > 0 ? '/' + segments.slice(0, currentDepth - 1).join('/') : '/'
  const branchBase = getBranchBase(segments)

  // Global history check: if the immediately previous route is from a different branch, use it.
  if (history.length > 1) {
    const previousRoute = history[history.length - 2]
    const prevSegments = previousRoute.split('/').filter(Boolean)
    const prevBranch = getBranchBase(prevSegments)
    if (prevBranch !== branchBase) {
      return previousRoute
    }
  }

  // If the immediate parent is still deeper than the branch base,
  // then our algorithm says "jump back" to the branch base.
  if (immediateParent !== branchBase) {
    return branchBase
  }

  // Otherwise, fall back to the immediate parent.
  return immediateParent
}

/**
 * useGoBack returns a function that navigates "back" using our algorithm.
 *
 * We always navigate with { replace: true, state: { skipHistory: true } } so that
 * programmatic back navigations aren’t re‑recorded in the global history.
 */
export function useGoBack({ backTo }: UseGoBackOptions = {}): () => void {
  const navigate = useNavigate()
  const location = useLocation()
  const { history } = useHistoryContext()

  const goBack = () => {
    if (backTo) {
      navigate(backTo, { replace: true, state: { skipHistory: true } })
      return
    }
    const target = getBackRoute(location.pathname, history)
    navigate(target, { replace: true, state: { skipHistory: true } })
  }

  return goBack
}
