import type { User } from '@/connect-types/user/user.type'

export enum AvailableRoles {
  SuperAdmin = 'Super Admin',
  Reseller = 'Reseller',
  Admin = 'Admin',
  Moderator = 'Moderator',
  Reports = 'Reports',
  Marketeer = 'Marketeer',
  Booking = 'Booking',
  FrontOfHouse = 'Front of House',
  ITSupport = 'IT Support',
}

export interface UserRoleType {
  legacy_id: number
  name: AvailableRoles
  id: string
  key:
    | 'super'
    | 'reseller'
    | 'moderator'
    | 'reports'
    | 'marketeer'
    | 'booking'
    | 'admin'
    | 'front-of-house'
    | 'it-support'
}

export interface OrganisationUsersAccess {
  organization_id: string
  user_id: string
  role_id: string | 'no-edit'
  oauth_users: User
  role: UserRoleType
}
