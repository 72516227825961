'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { EllipsisIcon, Search, Share2 } from 'lucide-react'
import { Fragment, type ReactNode, useEffect, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Icn,
  Modal,
  Portal,
  SIconButton,
  StampedeIcon,
  StampedeWordmark,
  Tooltip,
} from 'tailwind-ui'
import {
  ArrowPathIcon,
  ArrowsRightLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  CogIcon,
} from '@heroicons/react/24/outline'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'
import {
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSeparator,
  Menu,
} from '@headlessui/react'
import {
  ArrowDownOnSquareIcon,
  ChevronLeftIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  QrCodeIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid'
import { useMenu } from './menu-hook'
import SearchResults from '@/components/profile-search'
import { cn } from '@/lib/utils'
import { useSideNavContext } from '@/utils/router/sidenav-provider'
import { useUserInvite } from '@/providers/user-invite.context'
import { useSelectedVenue } from '@/state/backend/venues/venues.hooks'
import { generateImage } from '@/helpers/image'
import { isInStandaloneMode } from '@/utils/helpers/pwa'
import { useAddToHomescreenPrompt } from '@/hooks/useAddToHomescreenPrompt'
import { AuthWithPinSimpleButton } from './account/organisation/pin-auth'
import useUser from '@/hooks/useUser'
import { ConditionalPortal } from '@/layouts/navigation/tabbed-nav'
import { toast } from 'sonner'
import morpheusApi from '@/state/morpheus/morpheus.slice'

interface SidebarProps {
  className?: string
}

export function SidebarCollapseToggles() {
  const [
    {
      isMainNavCollapsed: isCollapsed,
      isSecondaryNavCollapsed,
      hasSecondaryNav,
      isNavCollapsed,
    },
    setNavState,
  ] = useSideNavContext()
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])
  const navigate = useNavigate()

  return (
    <div
      className={cn(
        'gap-1 p-2',
        'bg-neutral-100 dark:bg-neutral-900 rounded-full p-0.5 border border-black/10 dark:border-white/10',
        'inline-flex  transition-all duration-300   z-50  left-0 justify-center items-center',
        {
          'flex-row': !isSecondaryNavCollapsed || !isNavCollapsed,
          'md:flex-col':
            (isSecondaryNavCollapsed && !isNavCollapsed) || isNavCollapsed,
          'max-md:flex-col': isNavCollapsed,
        }
      )}
    >
      <SIconButton
        isRound
        size="xs"
        variant="ghost_default"
        icon={ChevronLeftIcon}
        onClick={() => {
          navigate(-1)
        }}
      />

      <div
        className={cn(
          'w-[1px] opacity-0 md:opacity-100 h-4 bg-black/10 dark:bg-white/10',
          {
            'h-1': isSecondaryNavCollapsed || isNavCollapsed,
          }
        )}
      />
      <SIconButton
        isRound
        variant="ghost_default"
        size="xs"
        icon={
          (!isSecondaryNavCollapsed && !isMobile) ||
          (!isNavCollapsed && isMobile)
            ? ChevronDoubleLeftIcon
            : ChevronDoubleRightIcon
        }
        onClick={() => {
          if (!isMobile) {
            setNavState((s) => ({
              ...s,
              isSecondaryNavCollapsed: !s.isSecondaryNavCollapsed,
            }))
            return
          }

          setNavState((s) => ({
            ...s,
            isMainNavCollapsed: Boolean(hasSecondaryNav),
            isSecondaryNavCollapsed: !hasSecondaryNav,
            isNavCollapsed: !s.isNavCollapsed,
            // isMainNavCollapsed: hasSecondaryNav ? true : !isCollapsed,
          }))
        }}
      />
    </div>
  )
}

export function SidebarPortal({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  const [{ isMainNavCollapsed, isSecondaryNavCollapsed }, setSideNavState] =
    useSideNavContext()
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])
  const navigate = useNavigate()
  return (
    <Portal
      className={cn(
        ' absolute transition-all duration-200 z-50 m-2 h-full md:border-l border-black/5 dark:border-white/5',
        {
          'w-[48px]': isSecondaryNavCollapsed,
          'w-[calc(100%-4.9rem)] md:w-[292px] max-md:right-2  z-50':
            !isSecondaryNavCollapsed,
          'border-l': width < 768 && !isSecondaryNavCollapsed,
          'left-16 ': isMainNavCollapsed && width >= 768,
          'left-0': isMainNavCollapsed && width < 768,
          'left-16':
            isMainNavCollapsed && width < 768 && !isSecondaryNavCollapsed,
          'left-[292px]': !isMainNavCollapsed,
        },
        className
      )}
    >
      {children}
    </Portal>
  )
}

export const navButtonClasses = ({
  isCollapsed,
  isActive,
}: {
  isCollapsed: boolean
  isActive: boolean
}) =>
  cn(
    'font-semibold text-md font-[Montserrat]',
    'rounded-lg group',
    'flex items-center',
    'relative h-10',
    'transition-colors duration-100',
    ' w-full text-neutral-400 dark:text-neutral-400',
    'hover:bg-neutral-100 dark:hover:bg-neutral-800 ',
    isCollapsed ? ' justify-center w-10' : 'px-3 justify-start ',
    isActive
      ? 'bg-[#FFF0F7] dark:bg-[#4E0D30] dark:text-neutral-300 active'
      : '',
    {
      'text-stampede-500': isActive && isCollapsed,
    }
  )

export function AnimatedSidebar({ className }: SidebarProps) {
  const [
    {
      isMainNavCollapsed: isCollapsed,
      hasSecondaryNav,
      isSecondaryNavCollapsed,
      isNavCollapsed,
    },
    setNavState,
  ] = useSideNavContext()

  const toggleSidebar = () => {
    setNavState((prev) => ({
      ...prev,
      isMainNavCollapsed: !prev.isMainNavCollapsed,
    }))
  }

  const {
    data: organisations = { data: [] },
    isLoading: isOrganisationLoading,
  } = morpheusApi.useGetOrganisationsSearchQuery({
    limit: 2,
  })

  const hasMutipleOrganisations = useMemo(
    () => organisations.data.length > 1,
    [organisations.data]
  )
  const location = useLocation()
  const { width: deviceWidth } = useWindowSize()

  const isMobile = useMemo(() => deviceWidth < 768, [deviceWidth])

  const navigate = useNavigate()

  const width = useMemo(() => {
    if (isMobile) {
      if (isNavCollapsed) {
        return 0
      }
      return deviceWidth - 14
    }
    if (!hasSecondaryNav) {
      return isCollapsed ? 64 : 292
    }
    if (isSecondaryNavCollapsed && isCollapsed) {
      return 64 + 48
    }

    if (!isSecondaryNavCollapsed && isCollapsed) {
      return 64 + 292
    }
    if (!isCollapsed && isSecondaryNavCollapsed) {
      return 292 + 48
    }

    //double havs
    if (!isSecondaryNavCollapsed && !isCollapsed) {
      return 292 * 2
    }

    return 292
  }, [
    hasSecondaryNav,
    isCollapsed,
    isSecondaryNavCollapsed,
    isMobile,
    isNavCollapsed,
    deviceWidth,
  ])

  const { onOpen } = useUserInvite()

  const menuItems = useMenu({
    toInclude: [
      'admin',
      'home',
      'venues',
      'marketing',
      'inbox',
      'reviews',
      'loyalty',
      'gift-cards',
      'reports',
      'bookings',
      'wi-fi',
    ],
  })
  const { serial, org_id } = useParams<{ serial: string; org_id: string }>()
  const pathname = useLocation().pathname
  const user = useUser()
  const { data: selectedVenue } = useSelectedVenue(serial)
  const [shadowVenue, setShadowVenue] = useLocalStorage(
    `active-menu-venue`,
    selectedVenue
  )

  useEffect(() => {
    if (!selectedVenue) return
    setShadowVenue(selectedVenue)
  }, [selectedVenue])

  const [prompt, promptToInstall] = useAddToHomescreenPrompt()

  const [searchVisible, setSearchVisible] = useState(false)
  return (
    <>
      <Modal
        title="Guest Search"
        isOpen={searchVisible}
        onClose={() => {
          setSearchVisible(false)
        }}
        classNames={{
          body: 'pb-2',
        }}
      >
        <SearchResults limit={5} />
      </Modal>
      <motion.div
        className={cn(
          'shadow-sm',
          'absolute md:sticky md:top-2 w-auto',
          'flex-none ml-2 my-2 flex-col overflow-auto overflow-x-hidden border border-black/5 dark:border-white/5 rounded-2xl z-10',
          // '[.page-has-side-nav_&]:rounded-r-none',
          'z-50 bottom-0',
          'bg-neutral-50/90 dark:bg-neutral-900/90 backdrop-blur-lg',
          // 'absolute md:relative h-16',

          `md:w-[${width}px]`,
          //   isMobileNavOpen ? 'h-16 md:h-auto' : '',
          className,
          {
            'max-md:h-[calc(100vh-var(--safe-bottom))]  pb-8 top-0': isMobile,
            // 'h-16  bottom-0': !isMobileNavOpen && isMobile,
          }
        )}
        animate={{
          width,
          x: !isNavCollapsed ? 0 : -72,
        }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
      >
        <div
          className={cn('flex relative  h-full flex-col', {
            'items-': isCollapsed && hasSecondaryNav,
            'w-[64px] items-center': isCollapsed,
            'w-full md:w-[292px]': !isCollapsed,
          })}
        >
          <div
            className={cn(
              'flex flex-col items-center ',
              isCollapsed ? 'px-2 pt-2' : 'px-4  pt-4 pb-2'
            )}
          >
            <AnimatePresence mode="popLayout">
              {!isCollapsed ? (
                <motion.button
                  onClick={() => {
                    if (isMobile && !hasSecondaryNav) {
                      return
                    }
                    setNavState((prev) => ({
                      ...prev,
                      isMainNavCollapsed: true,
                      isSecondaryNavCollapsed: !isSecondaryNavCollapsed,
                    }))
                  }}
                  key="stmpd-wordmark"
                  className="flex items-center justify-between w-full"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="flex items-center gap-4">
                    <div className="flex size-8 items-center justify-center rounded-lg bg-pink-500 text-white">
                      <StampedeIcon className="size-7 fill-white" />
                    </div>

                    <StampedeWordmark className="fill-black h-8 dark:fill-white" />
                  </div>
                  <Tooltip information="QR Code Redemption Scanner">
                    <div>
                      <NavLink
                        to="scanner"
                        className={({ isActive }) =>
                          navButtonClasses({
                            isCollapsed: true,
                            isActive,
                          })
                        }
                      >
                        <Icn icon={QrCodeIcon} className="size-5" />
                      </NavLink>
                    </div>
                  </Tooltip>
                </motion.button>
              ) : (
                <div className="flex items-center justify-between w-full">
                  <motion.button
                    key="stmpd-icon"
                    onClick={() => {
                      setNavState((prev) => ({
                        ...prev,
                        isMainNavCollapsed: isMobile
                          ? !prev.isMainNavCollapsed
                          : false,
                        isSecondaryNavCollapsed: hasSecondaryNav
                          ? !prev.isSecondaryNavCollapsed
                          : true,
                      }))
                    }}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 0.2 }}
                    className="flex w-full  items-center justify-center"
                  >
                    <div className="flex size-12 items-center justify-center rounded-xl bg-pink-500 text-white">
                      <StampedeIcon className="size-10 fill-white" />
                    </div>
                  </motion.button>
                </div>
              )}
            </AnimatePresence>
          </div>

          <div
            className={cn(
              'flex items-center ',
              isCollapsed ? 'px-2 py-4 pb-2 pt-6' : 'px-4 py-2 pb-1'
            )}
          >
            <AnimatePresence mode="popLayout">
              {!isCollapsed ? (
                <motion.button
                  key="search-button"
                  onClick={() => {
                    setSearchVisible(true)
                  }}
                  className="relative w-full bg-white dark:bg-black shadow-sm px-2 h-10 flex items-center border border-black/20 dark:border-white/20 rounded-lg"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{
                    opacity: 0,
                    scale: 0.9,
                    transition: { duration: 0 },
                  }}
                  transition={{ duration: 0.2 }}
                >
                  <Search className="size-5 text-neutral-500 mr-2" />
                  <p className="font-light text-neutral-400">Search</p>
                  <div className="text-xs ml-auto text-neutral-500 border border-black/10 dark:border-white/10 rounded-md px-1 py-0.5">
                    ⌘K
                  </div>
                </motion.button>
              ) : (
                <motion.button
                  key="search-button-collapsed"
                  onClick={() => {
                    setSearchVisible(true)
                  }}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{
                    opacity: 0,
                    scale: 0.9,
                    transition: { duration: 0 },
                  }}
                  transition={{ duration: 0.2 }}
                  className="flex w-full justify-center text-neutral-500"
                >
                  <Search className="size-5" />
                </motion.button>
              )}
            </AnimatePresence>
          </div>

          <div className="flex-1  pt-2 pb-4">
            <nav
              className={cn(
                'flex flex-col gap-1 ',
                isCollapsed ? 'px-1.5 justify-center' : 'px-4'
              )}
            >
              {menuItems.visible.map((item) => (
                <NavLink
                  key={item.id}
                  to={item.to}
                  end={item.to === ''}
                  className={({ isActive }) =>
                    navButtonClasses({
                      isCollapsed,
                      isActive,
                    })
                  }
                >
                  <Tooltip
                    side="right"
                    information={isCollapsed ? item.name : ''}
                  >
                    <div className="flex items-center justify-center">
                      <Icn
                        icon={item.icon}
                        className={cn(
                          'transition-colors duration-100',
                          'size-5  group-hover:text-stampede-500',
                          'group-[.active]:!text-stampede-500 dark:group-[.active]:text-stampede-500'
                        )}
                      />
                    </div>
                  </Tooltip>
                  <AnimatePresence mode="popLayout">
                    {!isCollapsed && (
                      <motion.span
                        className="ml-2 text-nowrap text-neutral-700 dark:text-neutral-300"
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: 'auto' }}
                        exit={{
                          opacity: 0,
                          width: 0,
                          transition: { duration: 0 },
                        }}
                        transition={{ duration: 0.2 }}
                        key={item.id}
                      >
                        {item.name}
                      </motion.span>
                    )}
                  </AnimatePresence>
                  {item.count ? (
                    <div
                      className={cn(
                        'text-[9px] font-sans font-bold',
                        'ml-auto size-5 bg-red-500 rounded-full text-white flex items-center justify-center',
                        isCollapsed ? 'absolute right-0 top-0' : ''
                      )}
                    >
                      <span>{item.count}</span>
                    </div>
                  ) : null}
                </NavLink>
              ))}
            </nav>
          </div>

          <div className="mt-auto pt-4  border-t border-black/5 dark:border-white/5">
            <nav
              className={cn(
                'grid gap-1  px-4 pb-2',
                isCollapsed ? 'justify-center' : ''
              )}
            >
              {pathname.includes('admin') ? null : (
                <button
                  className={navButtonClasses({
                    isCollapsed,
                    isActive: false,
                  })}
                  onClick={onOpen}
                >
                  <Share2 className="size-5" />
                  {!isCollapsed && <span className="ml-2">Share</span>}
                </button>
              )}

              <button
                onClick={async () => {
                  await navigator.serviceWorker
                    .getRegistrations()
                    .then(async (registrations) => {
                      await Promise.all(
                        registrations.map((registration) =>
                          registration.unregister()
                        )
                      )
                      window.location.reload()
                    })
                }}
                className={navButtonClasses({
                  isCollapsed,
                  isActive: false,
                })}
              >
                <Icn icon={ArrowPathIcon} className="size-5" />
                {!isCollapsed && <span className="ml-2">Reload App</span>}
              </button>

              {pathname.includes('admin') ? null : (
                <NavLink
                  to="account/profile"
                  className={({ isActive }) =>
                    navButtonClasses({
                      isCollapsed,
                      isActive,
                    })
                  }
                >
                  <Icn icon={Cog6ToothIcon} className="size-5" />
                  {!isCollapsed && <span className="ml-2">Settings</span>}
                </NavLink>
              )}
              <AuthWithPinSimpleButton
                className={navButtonClasses({
                  isCollapsed,
                  isActive: false,
                })}
              >
                <Icn icon={FingerPrintIcon} className="size-5" />
                {!isCollapsed && <span className="ml-2">Switch User</span>}
              </AuthWithPinSimpleButton>
              {prompt ? (
                <button
                  className={navButtonClasses({
                    isCollapsed,
                    isActive: false,
                  })}
                  onClick={promptToInstall}
                >
                  <ArrowDownOnSquareIcon className="size-5" />
                  {!isCollapsed && <span className="ml-2">Install App</span>}
                </button>
              ) : null}

              {isCollapsed ? null : (
                <button
                  className={navButtonClasses({
                    isCollapsed,
                    isActive: false,
                  })}
                  onClick={() => {
                    toast.info('Confirm account logout', {
                      action: {
                        label: 'Logout',
                        onClick: () => {
                          navigate('/oauth/logout')
                        },
                      },
                    })
                  }}
                >
                  <Icn icon={LockClosedIcon} className="size-5" />
                  {!isCollapsed && <span className="ml-2">Logout</span>}
                </button>
              )}

              {!hasMutipleOrganisations ? null : (
                <NavLink
                  to="/"
                  className={navButtonClasses({
                    isCollapsed,
                    isActive: false,
                  })}
                >
                  <Icn icon={ArrowsRightLeftIcon} className="size-5" />
                  {!isCollapsed && (
                    <span className="ml-2">Switch Organisation</span>
                  )}
                </NavLink>
              )}
              {isMobile ? null : (
                <button
                  className={navButtonClasses({
                    isCollapsed,
                    isActive: false,
                  })}
                  onClick={toggleSidebar}
                >
                  <Icn
                    icon={
                      !isCollapsed
                        ? ChevronDoubleLeftIcon
                        : ChevronDoubleRightIcon
                    }
                    className="size-5"
                  />
                  {!isCollapsed && (
                    <span className="ml-2">Collapse Sidebar</span>
                  )}
                </button>
              )}
            </nav>
            {!selectedVenue ? null : (
              <Menu
                as="div"
                className={cn('p-2', isCollapsed ? 'px-2' : 'px-4')}
              >
                <MenuButton
                  className={cn(
                    'flex items-center  gap-2 rounded-xl   hover:bg-neutral-100 dark:hover:bg-neutral-800 w-full',
                    isCollapsed ? 'justify-center' : ''
                  )}
                >
                  <div className="size-10 rounded-xl overflow-hidden bg-neutral-800 dark:bg-neutral-200">
                    <img
                      src={generateImage(
                        shadowVenue?.branding_settings.headerImage,
                        'avatar'
                      )}
                      alt="User avatar"
                      className="h-full w-full  object-cover"
                    />
                  </div>
                  <AnimatePresence mode="wait">
                    {!isCollapsed && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{
                          opacity: 0,
                          transition: { duration: 0 },
                        }}
                        transition={{ duration: 0.2 }}
                        className="flex flex-col text-left "
                      >
                        <span className="elipsis line-clamp-1  text-sm font-medium leading-none text-neutral-700 dark:text-neutral-300">
                          {shadowVenue?.alias}
                        </span>
                        <span className="text-xs text-neutral-500">
                          {user?.email}
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </MenuButton>
                <MenuItems
                  portal
                  transition
                  anchor="left start"
                  className="w-52 z-50 ml-3 fixed origin-top-left rounded-xl border border-black/10 dark:border-white/10 bg-neutral-50 dark:bg-neutral-900  p-1 focus:outline-none space-y-0.5 backdrop-blur-lg"
                >
                  {menuItems.venue.map((item) => (
                    <MenuItem
                      key={item.id}
                      as={NavLink}
                      to={item.to}
                      className={cn(
                        'group flex text-md w-full   items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                      )}
                    >
                      {item.icon ? (
                        <Icn
                          icon={item.icon}
                          className="size-4 text-black/30 dark:text-white/30"
                        />
                      ) : null}
                      <span className="truncate">{item.name}</span>
                    </MenuItem>
                  ))}
                  <MenuSeparator />
                  <MenuItem
                    as={NavLink}
                    to="venues"
                    className={cn(
                      'group flex text-md w-full  items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                    )}
                  >
                    <Icn
                      icon={ArrowsRightLeftIcon}
                      className="size-4 fill-black/30 dark:fill-white/30"
                    />
                    <span>Switch Venue</span>
                  </MenuItem>
                </MenuItems>
              </Menu>
            )}
          </div>
        </div>
      </motion.div>

      {isMobile ? (
        <Portal
          className={cn('absolute    pb-safe-offset-0  z-50', {
            'top-4 right-4  z-[999999] bg-black/10 dark:bg-white/10':
              !isNavCollapsed,
            'top-5 right-16': !isNavCollapsed && isSecondaryNavCollapsed,
            'bottom-0 left-0 right-0 z-[999999]  bg-neutral-100/90 dark:bg-neutral-800/90 backdrop-blur-lg md:p-2':
              isNavCollapsed,
          })}
        >
          <div className="flex  gap-1 py-2 items-center justify-evenly">
            {!isNavCollapsed ? (
              <></>
            ) : (
              <>
                {menuItems.visible.slice(0, 4).map((item, index) => (
                  <Fragment key={item.id}>
                    <NavLink
                      end={item.to === '/' || item.to === ''}
                      to={item.to}
                      className={({ isActive }) =>
                        cn(
                          'flex text-[10px] overflow-hidden max-w-[calc(100%/5)]  font-montserrat flex-col whitespace-nowrap items-center  text-sm font-medium  text-neutral-500 dark:text-neutral-400',
                          {
                            'text-stampede-500 dark:text-stampede-500':
                              isActive,
                          }
                        )
                      }
                    >
                      <Icn icon={item.icon} className="size-6" />
                      <span className="  text-ellipsis font-[10px]">
                        {item.name}
                      </span>
                    </NavLink>
                    {index < menuItems.visible.length - 1 && (
                      <span className="hidden w-1 h-1 min-w-1 rounded-full bg-neutral-300 dark:bg-neutral-600" />
                    )}
                  </Fragment>
                ))}
              </>
            )}
            <button
              className={cn(
                'flex text-[10px] font-montserrat flex-col whitespace-nowrap  items-center  text-sm font-medium min-w-fit text-neutral-500 dark:text-neutral-400'
              )}
              onClick={() => {
                setNavState((prev) => ({
                  ...prev,
                  isNavCollapsed: !prev.isNavCollapsed,
                  isMainNavCollapsed: prev.hasSecondaryNav,
                  isSecondaryNavCollapsed: !prev.hasSecondaryNav,
                }))
              }}
            >
              <Icn icon={EllipsisIcon} className="size-6" />
              <span>More</span>
            </button>
          </div>
        </Portal>
      ) : null}

      {!hasSecondaryNav || isMobile ? null : (
        <motion.div
          className="absolute top-4 z-[99] slider-nav-toggle"
          animate={{
            left:
              width === 0
                ? 16
                : isSecondaryNavCollapsed
                  ? isMobile
                    ? width - 128
                    : width - 32
                  : width - 64,
          }}
        >
          <SidebarCollapseToggles />
        </motion.div>
      )}
    </>
  )
}
