import { useEffect, useState, useId } from 'react'
import { motion } from 'framer-motion'
import style from './index.module.css'

function BubbleLoad({ text = 'Redirecting...' }: { text?: string }) {
  const id = useId()
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (!showLoader) return null
  return (
    <motion.div
      key={id}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.5 }}
      className="align-middle items-center  w-full h-full flex flex-col text-center justify-center flex-1"
    >
      <div className="pb-10 relative">
        <span className={`${style.bubble} ${style.bubble1} bg-stampede-500`} />
        <span
          className={`${style.bubble} ${style.bubble2} bg-stampede-500/80`}
        />
        <span
          className={`${style.bubble} ${style.bubble3} bg-stampede-500/60`}
        />
        <span
          className={`${style.bubble} ${style.bubble4} bg-stampede-500/40`}
        />
        <span
          className={`${style.bubble} ${style.bubble5} bg-stampede-500/20`}
        />
      </div>

      <div className="max-w-[250px] w-full">
        <p className="text-neutral-800 dark:text-neutral-200">{text}</p>
      </div>
    </motion.div>
  )
}

export default BubbleLoad
