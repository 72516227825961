import { Box } from '@chakra-ui/react'
import type { FunctionComponent } from 'preact'
import type { ReactQuillProps } from 'react-quill'
import ReactQuillLib from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

import './quill.css'
import { cn, inputVariants, Portal, Textarea } from 'tailwind-ui'

const QuillTextarea: FunctionComponent<ReactQuillProps> = ({
  className,
  ...props
}) => {
  return (
    <ReactQuillLib
      // Remove "static" and let the wrapper handle positioning
      className={cn('w-full focus:outline-none px-0 py-2', className)}
      // You can remove or modify style={{ height: '100%' }}
      theme="bubble"
      formats={[
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'align',
      ]}
      modules={{
        toolbar: [
          [{ header: '1' }, { header: '2' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link'],
          ['clean'],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],
        ],
        clipboard: {
          matchVisual: false,
        },
      }}
      {...props}
    />
  )
}

export const QuillTextareaWrapped: FunctionComponent<ReactQuillProps> = (
  props
) => {
  return (
    <div
      // Use relative + overflow-visible so the bubble theme tooltip is not clipped
      className={cn(
        inputVariants({ variant: 'flushed' }),
        'relative flex h-auto overflow-visible'
      )}
    >
      <QuillTextarea {...props} />
    </div>
  )
}
export default QuillTextarea
