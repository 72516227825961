import type { IconButtonProps, ComponentWithAs } from '@chakra-ui/react'
import { Switch, Text } from '@chakra-ui/react'
import { FunnelIcon, CheckIcon } from '@heroicons/react/16/solid'
import { differenceInMonths } from 'date-fns'
import { useCallback, useMemo } from 'react'
import {
  BooleanParam,
  createEnumParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import {
  SIconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Select,
  Icn,
} from 'tailwind-ui'
import { useParams } from 'react-router-dom'
import Label from 'tailwind-ui/src/forms/labels/label'
import useDataSources from '@/hooks/useDataSources'
import useLocations from '@/state/entities/locations/hooks/useLocation'
import { useGlobalQuery } from '@/state/global/global.hooks'
import {
  review_platforms,
  review_sentiments,
} from '@/connect-types/reviews/reviews.type'
import type { TimeGroupTypes } from '@/utils/TimeGenerator'
import { timeGroups } from '@/utils/TimeGenerator'
import { ThreadTagCreateSelect } from '@/components/inbox/tags/thread-tags-create-select'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import tankApi from '@/state/tank/tank.slice'
import { useSegments } from '@/hooks/useSegment'

const FILTER_TYPES = [
  'serial',
  'data_source',
  'inbox_thread_tag',
  'context_type',
  'assignee',
  'group_by_time',
  'sort_by',
  'review_platforms',
  'review_sentiment',
  'review_rating',
  'review_page',
  'redirect_conversion',
  'show_child_organisations',
  'segment',
  'tag_id',
] as const
type FilterType = (typeof FILTER_TYPES)[number]

const GroupByOrderEnumParam = createEnumParam(timeGroups)
const SortByOrderEnumParam = createEnumParam(['desc', 'asc'])
const ReviewPlatformsEnumParam = createEnumParam([...review_platforms])
const ReviewSentimentsEnumParam = createEnumParam([...review_sentiments])
const ReviewRatingEnumParam = createEnumParam(['1', '2', '3', '4', '5'])

export const useFilters = (
  initProps: {
    serial?: string
    group_by_time?: TimeGroupTypes
    show_child_organisations?: boolean
    onFilterChange?: () => void
  } = {
    serial: '',
    group_by_time: 'month',
    show_child_organisations: false,
  }
) => {
  const { serial } = useParams<{ serial?: string }>()

  const [filters, setFilters] = useQueryParams({
    serial: withDefault(StringParam, serial ?? initProps.serial ?? ''),
    data_source: withDefault(StringParam, ''),
    inbox_thread_tag: withDefault(StringParam, ''),
    assignee: withDefault(StringParam, ''),
    context_type: withDefault(StringParam, ''),
    group_by_time: withDefault(GroupByOrderEnumParam, initProps.group_by_time),
    sort_by: withDefault(SortByOrderEnumParam, 'desc'),
    review_platforms: withDefault(ReviewPlatformsEnumParam, null),
    review_sentiment: withDefault(ReviewSentimentsEnumParam, null),
    review_rating: withDefault(ReviewRatingEnumParam, null),
    review_page: withDefault(StringParam, ''),
    redirect_conversion: withDefault(BooleanParam, false),
    show_child_organisations: withDefault(
      BooleanParam,
      initProps.show_child_organisations ?? false
    ),
    segment: withDefault(StringParam, ''),
    tag_id: withDefault(StringParam, ''),
  })

  const setFiltersWrapped = useCallback(
    (newFilters: typeof filters) => {
      if (initProps.onFilterChange) {
        initProps.onFilterChange()
      }

      setFilters(newFilters)
    },
    [initProps, setFilters]
  )

  //TimeGroupTypes
  return [filters, setFiltersWrapped] as const
}

export function FiltersDrawButton({
  includes = [],
  showAllSerials = false,
  ...rest
}: ComponentWithAs<'button', Omit<IconButtonProps, 'aria-label'>> & {
  includes?: FilterType[]
  showAllSerials: boolean
  onFilterChange?: () => void
}) {
  const [filters] = useFilters({ onFilterChange: rest.onFilterChange })
  const { serial } = useParams<{ serial?: string }>()
  const isFilterActive = useMemo(() => {
    const items = Object.keys(filters)
      .filter((item) => !['sort_by', 'group_by_time'].includes(item))
      .filter(
        (key) =>
          includes.includes(key as FilterType) &&
          Boolean(filters[key]) &&
          filters[key] !== 'all'
      )
    return items
  }, [includes, filters])

  const includeItems = useMemo(() => {
    return includes.filter((item) => {
      if (item === 'serial' && Boolean(serial) && !showAllSerials) {
        return false
      }
      return true
    })
  }, [includes, serial])

  if (includeItems.length === 0) {
    return null
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="relative">
          <SIconButton
            variant="ghost_default"
            isRound
            aria-label="filter"
            icon={FunnelIcon}
            {...rest}
          />
          {isFilterActive.length >= 1 && (
            <div className="absolute w-[12px] h-[12px] -right-[1px] -top-[2px] bg-green-500 rounded-full" />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="min-w-full">
        <p className=" font-semibold text-gray-600 dark:text-gray-300">
          Filters
        </p>
        <Filters includes={includeItems} onFilterChange={rest.onFilterChange} />
      </PopoverContent>
    </Popover>
  )
}

function CheckMark() {
  return <Icn icon={CheckIcon} className="text-green-500" />
}

function Filters({
  includes = [],
  onFilterChange,
}: {
  includes?: FilterType[]
  onFilterChange?: () => void
}) {
  const [filters, setFilters] = useFilters({ onFilterChange })
  const data_sources = useDataSources()
  const venues = useLocations()
  const { data: segments } = useSegments()

  const { org_id: orgId } = useParams<{ org_id: string }>()

  const { data: users = [] } = morpheusApi.useGetOrganisationUsersQuery(
    {
      orgId,
    },
    {
      skip: !includes.includes('assignee'),
    }
  )

  const { data: reviewPages = [] } = tankApi.useGetOrgReviewPagesQuery(
    {
      orgId,
    },
    {
      skip: !includes.includes('review_page'),
    }
  )

  const { data: tags = [] } = morpheusApi.useGetTagsQuery(
    {
      orgId,
    },
    {
      skip: !includes.includes('tag_id'),
    }
  )

  const [query] = useGlobalQuery()
  const diff = differenceInMonths(
    new Date(query.endDate),
    new Date(query.startDate)
  )
  const disabledGroups: TimeGroupTypes[] = useMemo(() => {
    if (diff >= 12) {
      return ['hour', 'day']
    } else if (diff >= 4) {
      return ['hour']
    } else if (diff <= 3) {
      return ['month', 'hour']
    }

    return []
  }, [diff])

  return (
    <div className="space-y-2">
      {includes.includes('serial') && venues.length > 1 && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Venue</span>
            {Boolean(filters.serial) && <CheckMark />}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({ serial: ev.target.value })
            }}
            placeholder="Show all"
            variant="flushed"
            value={filters.serial}
          >
            <option value="">All</option>
            {venues.map((item) => (
              <option key={item.serial} value={item.serial}>
                {item.alias ?? item.serial}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('tag_id') && tags.length > 1 && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Tag</span>
            {Boolean(filters.tag_id) && <CheckMark />}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({ tag_id: ev.target.value })
            }}
            variant="flushed"
            placeholder="Show all"
            value={filters.tag_id}
          >
            <option value="">All</option>
            {tags.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('segment') && segments.length > 1 && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Segment</span>
            {Boolean(filters.segment) && filters.segment !== 'all' && (
              <CheckMark />
            )}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({ segment: ev.target.value })
            }}
            variant="flushed"
            placeholder="Show all"
            value={filters.segment}
          >
            {segments.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name ?? 'Unknown'}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('data_source') && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Data source</span>

            {Boolean(filters.data_source) && <CheckMark />}
          </Label>
          <Select
            onChange={(ev) => {
              setFilters({ data_source: ev.target.value })
            }}
            variant="flushed"
            placeholder="Show all"
            value={filters.data_source}
          >
            <option value="">All</option>
            {data_sources
              .toSorted((a, b) => (a.name > b.name ? 1 : -1))
              .map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
        </div>
      )}
      {includes.includes('assignee') && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Users</span>
            {Boolean(filters.assignee) && <CheckMark />}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({ assignee: ev.target.value })
            }}
            variant="flushed"
            placeholder="Show all"
            value={filters.assignee}
          >
            {users.map((item) => (
              <option key={item.user_id} value={item.user_id}>
                {item.oauth_users.first} {item.oauth_users.last}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('context_type') && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Message source</span>
            {Boolean(filters.context_type) && <CheckMark />}
          </Label>

          <Select
            variant="flushed"
            onChange={(ev) => {
              setFilters({ context_type: ev.target.value })
            }}
            placeholder="Show all"
            value={filters.context_type}
          >
            {[
              { label: 'Booking', value: 'booking' },
              { label: 'Form submission', value: 'form' },
            ].map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('inbox_thread_tag') && (
        <ThreadTagCreateSelect label="Thread tags" />
      )}
      {includes.includes('show_child_organisations') && (
        <div className="flex gap-2">
          <Label className="flex justify-between gap-1">
            <span>Show child organisations</span>
            {Boolean(filters.show_child_organisations) && <CheckMark />}
          </Label>
          <Switch
            defaultChecked={filters.show_child_organisations}
            isChecked={filters.show_child_organisations}
            onChange={(event) => {
              setFilters({ show_child_organisations: event.target.checked })
            }}
            pr={3}
          />
        </div>
      )}
      {includes.includes('group_by_time') && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Group by</span>
            {Boolean(filters.group_by_time) && <CheckMark />}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({ group_by_time: ev.target.value as TimeGroupTypes })
            }}
            value={filters.group_by_time}
          >
            {timeGroups.map((item) => (
              <option
                disabled={disabledGroups.includes(item)}
                key={item}
                value={item}
              >
                {item}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('sort_by') && (
        <div className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <span>Sort by</span>
            {Boolean(filters.sort_by) && <CheckMark />}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({ sort_by: ev.target.value as 'asc' | 'desc' })
            }}
            value={filters.sort_by}
          >
            {['desc', 'asc'].map((item) => (
              <option key={item} value={item}>
                {item === 'desc' ? 'Newest first' : 'Oldest first'}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('review_platforms') && (
        <div id="review_rating" className="flex flex-col space-y-1">
          <div className="flex gap-2">
            <Label className="flex justify-between gap-1">
              <span>Review platforms</span>
              {Boolean(filters.review_platforms) && <CheckMark />}
            </Label>
          </div>

          <Select
            onChange={(ev) => {
              setFilters({
                review_platforms: ev.target
                  .value as (typeof review_platforms)[number],
              })
            }}
            placeholder="Show all"
            value={filters.review_platforms}
          >
            <option value="">All</option>
            {review_platforms.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('review_sentiment') && (
        <div id="review_rating" className="flex flex-col space-y-1">
          <Label className="flex justify-between gap-1">
            <Text>Review sentiment</Text>
            {Boolean(filters.review_sentiment) && <CheckMark />}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({
                review_sentiment: ev.target
                  .value as (typeof review_sentiments)[number],
              })
            }}
            placeholder="Show all"
            value={filters.review_sentiment}
          >
            <option value="">All</option>
            {review_sentiments.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </div>
      )}
      {includes.includes('review_rating') && (
        <div id="review_rating" className="flex flex-col space-y-1">
          <Label className="flex gap-2">
            {Boolean(filters.review_rating) && <CheckMark />}
            <span>Review rating</span>
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({
                review_rating: ev.target.value,
              })
            }}
            placeholder="Show all"
            value={filters.review_rating}
          >
            <option value="">All</option>
            {['1', '2', '3', '4', '5'].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </div>
      )}

      {includes.includes('redirect_conversion') && (
        <div className="flex gap-2">
          <Label className="flex justify-between gap-1">
            <span>Show confirmed email-to-review conversions only</span>
            {Boolean(filters.redirect_conversion) && <CheckMark />}
          </Label>
          <Switch
            defaultChecked={filters.redirect_conversion}
            isChecked={filters.redirect_conversion}
            onChange={(event) => {
              setFilters({ redirect_conversion: event.target.checked })
            }}
            pr={3}
          />
        </div>
      )}
      {includes.includes('review_page') && (
        <div id="review_page" className="flex flex-col space-y-1">
          <Label className="flex gap-2">
            <span>Review page</span>
            {Boolean(filters.review_page) && <CheckMark />}
          </Label>

          <Select
            onChange={(ev) => {
              setFilters({
                review_page: ev.target.value,
              })
            }}
            placeholder="Show all"
            value={filters.review_page}
          >
            <option value="">All</option>
            {reviewPages.map((item) => (
              <option as="option" key={item.id} value={item.id}>
                {item.title_text}
              </option>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}

export default Filters
