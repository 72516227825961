import { throttle } from 'lodash-es'
import { useCallback, useLayoutEffect, useState, useMemo } from 'react'

const getPxNumber = (value: string) => {
  if (!value) return 0

  if (typeof value === 'string') {
    if (!value.toString().includes('px')) {
      return 0
    }
    return Number(value.toString().replace('px', ''))
  }
  return 0
}

const createThrottledResizeHandler = (
  heightOffset: number,
  setDimensions: (dims: { height: number; width: number }) => void
) =>
  throttle(() => {
    setDimensions({
      height: window.innerHeight - heightOffset,
      width: window.innerWidth,
    })
  }, 1000)

const useWindowWidth = () => {
  const heightOffset = useMemo(
    () =>
      getPxNumber(
        getComputedStyle(document.documentElement).getPropertyValue('--sat')
      ),
    []
  )

  const [dimensions, setDimensions] = useState(() => ({
    height: window.innerHeight - heightOffset,
    width: window.innerWidth,
  }))

  useLayoutEffect(() => {
    const throttledHandler = createThrottledResizeHandler(
      heightOffset,
      setDimensions
    )

    // Initial calculation
    throttledHandler()

    window.addEventListener('resize', throttledHandler)

    return () => {
      window.removeEventListener('resize', throttledHandler)
      throttledHandler.cancel() // Clean up throttled function
    }
  }, [heightOffset])

  return useMemo(() => dimensions, [dimensions])
}

export default useWindowWidth
