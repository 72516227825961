import { createAction } from 'typesafe-actions'
import { LoadedType } from './loaded.reducer'

export const setLoadedOrgId = createAction(
  'LOADED_SET_ORG_ID',
  (resolve) => (orgId: string) => resolve({ orgId })
)

export const setLoaded = createAction(
  'LOADED_SET_LOADED',
  (resolve) => (key: keyof LoadedType['loaded'], value: boolean) =>
    resolve({ key, value })
)

export const setLoadedOrganisation = createAction(
  'LOADED_SET_LOADED_ORG',
  (resolve) => (value: boolean) => resolve({ value })
)

export const setAllLoaded = createAction(
  'LOADED_ALL_SET_LOADED',
  (resolve) => (loaded: LoadedType['loaded']) => resolve({ loaded })
)

export const clearLoaded = createAction(
  'CLEAR_LOADED',
  (resolve) => () => resolve()
)
