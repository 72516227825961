import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import { toast } from 'sonner'
import message from '@/common/message/message'
import config from '@/config'
import type { LocalStorageCredentials } from '@/connect-types/auth/localStorageCredentials.type'
import type {
  BackendResponseType,
  ServiceCursorPaginatedResponseType,
} from '@/connect-types/backend/service'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import type { AddLocation } from '@/connect-types/locations/addLocation.type'
import type {
  PagenationRequestType,
  PaginationRepsonse,
} from '@/connect-types/pagination/pagination.type'
import type {
  CsvRequestType,
  CsvResponseType,
} from '@/connect-types/sources/csv.type'
import type { EndUser } from '@/connect-types/user/user.type'
import type { ApiUserCredentials } from '@/state/auth/auth.saga'
import type {
  InteractionRequestType,
  OrganizationRegistration,
} from '@/state/entities/interactions/interactions.types'
import type {
  LoyaltySchemeTagType,
  LoyaltySchemeType,
  RedemptionType,
} from '@/state/entities/loyalty/loyalty.types'
import type {
  Segment,
  SegmentPreview,
  SegmentReach,
  SegmentType,
  TagType,
} from '@/state/entities/segment/segment.types'
import { setToken } from '@/utils/AuthTokenHelper'
import { customAxios } from '@/utils/axiosHelper'
import { buildUrl } from '@/utils/common'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      console.log(err, 'error-here')
      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment') &&
        !url.includes('oauth')
      ) {
        ServiceErrorHandler(err)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const backendApi = createApi({
  reducerPath: 'backend',
  baseQuery: axiosBaseQuery({ baseUrl: config.url.auth }),

  tagTypes: [
    'Organisation',
    'Organisations',
    'Locations',
    'LocationsStories',
    'Progress',
    'User',
    'Vendors',
    'Location',
    'SIC',
    'UniFiControllers',
    'UniFiLocation',
    'OrganisationSettings',
    'OrganisationUsers',
    'OrganisationUsersVenues',
    'Forms',
    'CustomQuestions',
    'CustomQuestionsEntries',
    'GiftCards',
    'GiftCardsActivations',
    'StripeAccounts',
    'LoyaltySchemes',
    'LoyaltySchemesTags',
    'ReviewPages',
    'ReviewResponses',
    'MarketingOptOuts',
    'Segments',
    'ProfileTags',
    'EndUser',
    'SupportedRegions',
    'Csv',
    'MarketingMenus',
  ],

  endpoints: (build) => ({
    getAuthWithCode: build.query<
      LocalStorageCredentials,
      { code: string; redirect_uri?: string }
    >({
      query: ({ code, redirect_uri }) => ({
        url: `/oauth/token`,
        method: 'post',
        data: {
          code,
          client_id: config.auth.clientId,
          client_secret: config.auth.clientSecret,
          grant_type: 'authorization_code',
          redirect_uri: redirect_uri ?? config.auth.redirectUri,
        },
      }),
      transformResponse(response: ApiUserCredentials) {
        console.log({ trans: response })
        const credentials: LocalStorageCredentials = {
          accessToken: response.access_token,
          expiresIn: response.expires_in,
          refreshToken: response.refresh_token,
          scope: response.scope, // TODO: what is scope for?
          tokenType: response.token_type,
          expiresAt: response.expires_in
            ? Date.now() + response.expires_in * 1000
            : null,
        }

        setToken(credentials)

        return credentials
      },
      transformErrorResponse(error) {
        console.log({ transrr: error })
      },
    }),

    logout: build.mutation({
      query: () => ({
        url: '/members/me/logout',
        method: 'delete',
      }),
      transformResponse() {
        localStorage.setItem(config.localStorageKeys.userCredentials, null)
        return null
      },
      transformErrorResponse() {
        localStorage.setItem(config.localStorageKeys.userCredentials, null)
        return null
      },
    }),

    getSupportedRegions: build.query<string[], undefined>({
      query: () => ({
        url: `/public/supported-regions`,
        method: 'get',
      }),
      transformResponse(data: { regions: string[] }) {
        return data.regions
      },
      providesTags: ['SupportedRegions'],
    }),

    /**
     * STORIES
     */

    /**
     * END STORIES
     */

    removeVenueFromOrganisation: build.mutation<
      BackendResponseType<string>,
      { orgId: string; serial: string }
    >({
      query: ({ serial, orgId }) => ({
        url: `/organisations/${orgId}/admin/unlink-location-from-organisation`,
        data: { serial },
        method: 'delete',
      }),
      invalidatesTags: ['Organisation', 'Location'],
    }),

    addVenue: build.mutation<string, { orgId: string; data: AddLocation }>({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/billing/venue`,
        data,
        method: 'post',
      }),
      transformResponse(
        data: BackendResponseType<{ serial: string; venues: number }>
      ) {
        return data.message.serial
      },
      invalidatesTags: ['Organisation', 'Locations'],
    }),

    uploadCsv: build.mutation<CsvResponseType, CsvRequestType>({
      query: ({ orgId, serials, data_source, default_region, data }) => ({
        url: buildUrl(`/organisations/${orgId}/import/csv`, {
          serials,
          'data-source': data_source,
          default_region,
        }),

        method: 'post',
        headers: {
          'Content-Type': 'text/csv',
        },
        timeout: 1000 * 60,
        data,
      }),
      transformResponse(data: BackendResponseType<CsvResponseType>) {
        toast.success(
          `Data imported, added ${data.message.recordsSaved} records`
        )
        return data.message
      },
      transformErrorResponse: (error) => {
        toast.warning('Something went wrong')
        return error
      },
      invalidatesTags: ['Csv'],
    }),

    /**
     * LOYALTY START
     */
    getLoyaltySchemes: build.query<
      LoyaltySchemeType[],
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes`,
        method: 'get',
      }),

      providesTags: (items) => [
        ...(items ?? []).map((item) => ({
          type: 'LoyaltySchemes' as const,
          id: item?.id,
        })),
        { id: 'LIST', type: 'LoyaltySchemes' },
      ],
    }),

    createLoyaltyScheme: build.mutation<
      LoyaltySchemeType,
      {
        orgId: string
        scheme: Partial<LoyaltySchemeType>
      }
    >({
      query: ({ orgId, scheme }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes`,
        method: 'post',
        data: scheme,
      }),

      invalidatesTags: () => [{ id: 'LIST', type: 'LoyaltySchemes' }],
    }),
    updateLoyaltyScheme: build.mutation<
      LoyaltySchemeType,
      {
        orgId: string
        scheme: LoyaltySchemeType
      }
    >({
      query: ({ orgId, scheme: s }) => {
        const scheme = { ...s }
        Object.keys(scheme).forEach(
          (key) =>
            scheme[key as keyof LoyaltySchemeType] === null &&
            delete scheme[key as keyof LoyaltySchemeType]
        )

        return {
          url: `/organisations/${orgId}/loyalty/stamp/schemes/${scheme.id}`,
          method: 'put',
          data: scheme,
        }
      },

      transformResponse: (response: LoyaltySchemeType) => {
        message.success('Scheme updated')

        return response
      },
      invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemes' }],
    }),
    deleteLoyaltyScheme: build.mutation<
      LoyaltySchemeType,
      {
        orgId: string
        scheme: LoyaltySchemeType
      }
    >({
      query: ({ orgId, scheme }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${scheme.id}`,
        method: 'delete',
      }),

      invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemes' }],
    }),

    getLoyaltyScheme: build.query<
      LoyaltySchemeType,
      {
        orgId: string
        schemeId: string
      }
    >({
      query: ({ orgId, schemeId }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}`,
        method: 'get',
      }),
      providesTags: (item) => [{ id: item?.id, type: 'LoyaltySchemes' }],
    }),

    getLoyaltyTags: build.query<
      LoyaltySchemeTagType[],
      {
        orgId: string
        schemeId: string
      }
    >({
      query: ({ orgId, schemeId }) => ({
        method: 'get',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/secondary-id`,
      }),
      providesTags: (items) => [
        ...items.map((item) => ({
          id: item.id,
          type: 'LoyaltySchemesTags' as const,
        })),
        { id: 'LIST', type: 'LoyaltySchemesTags' },
      ],
    }),
    createLoyaltyTags: build.mutation<
      LoyaltySchemeTagType,
      {
        orgId: string
        schemeId: string
        tag: Partial<LoyaltySchemeTagType>
      }
    >({
      query: ({ orgId, schemeId, tag }) => ({
        method: 'post',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/secondary-id`,
        data: tag,
      }),
      invalidatesTags: () => [{ id: 'LIST', type: 'LoyaltySchemesTags' }],
    }),
    updateLoyaltyTags: build.mutation<
      LoyaltySchemeTagType,
      {
        orgId: string
        tag: LoyaltySchemeTagType
      }
    >({
      query: ({ orgId, tag }) => ({
        method: 'put',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${tag.scheme_id}/secondary-id/${tag.id}`,
        data: tag,
      }),
      invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),
    getLoyaltyRedeptionUsers: build.query<
      PaginationRepsonse<RedemptionType>,
      {
        orgId: string
        schemeId: string
        page: PagenationRequestType
      }
    >({
      query: ({ orgId, schemeId, page }) => ({
        method: 'get',
        url: buildUrl(
          `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/users/redemptions`,
          page
        ),
      }),
      // invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),
    createLoyaltyStamps: build.mutation<
      PaginationRepsonse<RedemptionType>,
      {
        orgId: string
        schemeId: string
        profileId: number
        stampsQty: number
      }
    >({
      query: ({ orgId, schemeId, stampsQty, profileId }) => ({
        method: 'post',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/users/${profileId}/stamps`,
        data: { stamps: stampsQty },
      }),
      // invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),

    getRegistrationInteractions: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistration>,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(
          `/organisations/${orgId}/interactions/registrations`,
          query
        ),
      }),
    }),

    getMarketingOptOuts: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistration>,
      {
        orgId: string
        query: PagenationRequestType
        email?: string
      }
    >({
      query: ({ orgId, query, email }) => ({
        method: 'get',
        url: buildUrl(`/marketing/${orgId}/opt-out`, { ...query, email }),
      }),
      providesTags: (items) => [
        ...(items?.data?.map((item) => ({
          id: item.id,
          type: 'MarketingOptOuts' as const,
        })) ?? []),
        {
          id: 'LIST',
          type: 'MarketingOptOuts',
        },
      ],
    }),
    createMarketingOptOuts: build.mutation<
      OrganizationRegistration,
      {
        orgId: string
        email: string
        email_opt_in: boolean
        sms_opt_in: boolean
      }
    >({
      query: ({ orgId, email, email_opt_in, sms_opt_in }) => ({
        method: 'post',
        url: buildUrl(`/public/organizations/${orgId}/opt`, {
          email,
          email_opt_in,
          sms_opt_in,
        }),
      }),
      invalidatesTags: (item) => [
        { id: item?.id, type: 'MarketingOptOuts' },
        { id: 'LIST', type: 'MarketingOptOuts' },
      ],
    }),

    //OrganizationRegistration

    /**
     * ----
     * SEGMENTS START
     * ----
     */

    getMetadataSegments: build.query<
      SegmentType,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/segments/metadata`,
        method: 'get',
      }),
      transformResponse(res: SegmentType) {
        const fieldsArray = Object.entries(res.fields).map(
          ([_key, value]) => value
        )

        return { ...res, fieldsArray }
      },
    }),
    //Apears getDataInSegment to be unused...
    getDataInSegment: build.query<
      PaginationRepsonse<SegmentPreview>,
      {
        orgId: string
        segmentId: string
        query: { offset: number; limit: number }
      }
    >({
      query: ({ orgId, segmentId, query }) => ({
        url: buildUrl(
          `/organisations/${orgId}/segments/${segmentId}/data`,
          query
        ),
        method: 'get',
      }),
    }),
    getSegmentReach: build.mutation<
      SegmentReach,
      {
        orgId: string
        segment: Segment
      }
    >({
      query: ({ orgId, segment }) => ({
        url: `/organisations/${orgId}/segments/${segment.id}/reach`,
        method: 'get',
      }),
      invalidatesTags: (_item, _other, { segment }) => [
        //  { type: 'Segments', id: 'LIST' },
        { type: 'Segments', id: segment.id },
      ],
    }),

    deleteSegment: build.mutation<
      unknown,
      {
        orgId: string
        segment: Segment
      }
    >({
      query: ({ orgId, segment }) => ({
        url: `/organisations/${orgId}/segments/${segment.id}?version=${segment.version}`,
        method: 'delete',
      }),
      invalidatesTags: [{ type: 'Segments', id: 'LIST' }],
      transformResponse: (item: unknown, _item2, { segment }) => {
        message.success(`${segment.name} deleted`)
        return item
      },
    }),
    /**
     * ----
     * SEGMENTS END
     * ----
     */

    /**
     * ----
     * USER QUERIES START
     * ---
     */
    getUsers: build.query<
      ServiceCursorPaginatedResponseType<EndUser & { org_reg_id: string }>,
      {
        orgId: string
        search?: string
        limit?: number
        cursor?: string
      }
    >({
      query: ({ orgId, search = '', limit = 5, cursor = '' }) => ({
        url: `/organisations/${orgId}/user-profiles/search?search=${search}&limit=${limit}&cursor=${cursor}`,
        method: 'get',
      }),
    }),
    getUserLoyalty: build.query<
      ServiceCursorPaginatedResponseType<LoyaltyDataType>,
      {
        orgId: string
        id: string
        cursor: string
      }
    >({
      query: ({ orgId, cursor, id }) => ({
        url: buildUrl(`/organisations/${orgId}/user-profiles/${id}/loyalty`, {
          cursor,
        }),
        method: 'get',
      }),
    }),

    addTagToUsers: build.mutation<
      unknown,
      { orgId: string; tag: TagType; profileIds: string[] | number[] }
    >({
      query: ({ orgId, tag, profileIds }) => ({
        url: `/organisations/${orgId}/crm/tags/profiles`,
        method: 'put',
        data: { name: tag.name, profiles: profileIds },
      }),
      invalidatesTags: (_1, _2, item) => [
        ...item.profileIds.map((item) => ({
          id: item.id,
          type: 'EndUser' as const,
        })),
        { id: 'LIST', type: 'EndUser' },
      ],
      transformResponse(item: unknown) {
        message.success('Tag added from profile(s)')
        return item
      },
    }),
    removeTagFromUser: build.mutation<
      unknown,
      { tagId: string; orgId: string; profileId: string | number }
    >({
      query: ({ orgId, tagId, profileId }) => ({
        url: `/organisations/${orgId}/crm/users/${profileId}/tags/${tagId}`,
        method: 'delete',
      }),
      invalidatesTags: (_1, _2, item) => [
        { id: item.profileId, type: 'EndUser' },
        { id: 'LIST', type: 'EndUser' },
      ],
      transformResponse(item: unknown) {
        message.success('Tag removed from profile')
        return item
      },
    }),
    createEndUserInteraction: build.mutation<
      { profile: EndUser; newSerials: string[] },
      {
        profile: Partial<EndUser>
        serials: string[]
        orgId: string
        tags: string[]
        source: 'web' | 'in-venue-form'
      }
    >({
      query: ({ profile, orgId, serials = [], source = 'web', tags = [] }) => ({
        url: `/public/organizations/${orgId}/email-registration`,
        data: {
          ...profile,
          source,
          serials,
          tags,
          dataOptIn: true,
          emailOptIn: true,
          smsOptIn: true,
        },
        method: 'post',
      }),
      transformResponse: (data: { profile: EndUser; newSerials: string[] }) => {
        message.success('Customer created')
        return data
      },
      invalidatesTags: [{ id: 'LIST', type: 'Segments' }],
    }),
    /**
     * ----
     * USER QUERIES END
     * ---
     */
  }),
})

export default backendApi
