import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'

interface SideNavContextType {
  isMainNavCollapsed: boolean
  isSecondaryNavCollapsed: boolean
  isNavCollapsed: boolean
  hasSecondaryNav: boolean
}

const SideNavContext = createContext<
  [SideNavContextType, Dispatch<SetStateAction<SideNavContextType>>]
>([
  {
    isMainNavCollapsed: false,
    isSecondaryNavCollapsed: false,
    hasSecondaryNav: false,
    isNavCollapsed: false,
  },
  () => {},
])

export const SideNavProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { width } = useWindowSize()
  const [navState, setNavStateProps] = useState({
    isMainNavCollapsed: width < 768,
    isSecondaryNavCollapsed: width < 768,
    hasSecondaryNav: false,
    isNavCollapsed: false,
  })

  const isMobile = useMemo(() => width < 768, [width])
  const locationKey = useLocation().key
  useEffect(() => {
    if (!isMobile) return
    setNavState((s) => ({
      ...s,
      isMainNavCollapsed: s.hasSecondaryNav ? true : isMobile,
      isSecondaryNavCollapsed: isMobile,
      //  hasSecondaryNav: s.hasSecondaryNav,
      isNavCollapsed: isMobile,
    }))
  }, [isMobile])

  const setNavState: Dispatch<SetStateAction<SideNavContextType>> = useCallback(
    (stateOrUpdater) => {
      // Handle both function and direct state updates
      const getNewState = (prevState: SideNavContextType) => {
        // If stateOrUpdater is a function, call it with previous state
        const nextState =
          typeof stateOrUpdater === 'function'
            ? stateOrUpdater(prevState)
            : stateOrUpdater

        const flip =
          nextState.isSecondaryNavCollapsed && nextState.isSecondaryNavCollapsed
        console.log(
          { nextState },
          'changes-suggested-hasSecondaryNav',
          nextState.hasSecondaryNav,
          {
            ...nextState,
            isSecondaryNavCollapsed: flip ? nextState.hasSecondaryNav : flip,
            isMainNavCollapsed: flip ? !nextState.hasSecondaryNav : !flip,
          }
        )

        // Apply mobile-specific logic
        if (isMobile) {
          return {
            ...nextState,
          }
        }

        return {
          ...nextState,
          isNavCollapsed: false,
        }
      }

      // Pass the updater function to the original setState
      setNavStateProps((prevState) => ({
        ...prevState,
        ...getNewState(prevState),
      }))
    },
    [setNavStateProps, isMobile]
  )

  useEffect(() => {
    if (!isMobile) return
    setNavState((s) => ({
      ...s,
      //  isSecondaryNavCollapsed: s.hasSecondaryNav ? true : isMobile,
      //  isMainNavCollapsed: isMobile,
      isNavCollapsed: true,
    }))
  }, [locationKey])

  return (
    <SideNavContext.Provider value={[navState, setNavState]}>
      {children}
    </SideNavContext.Provider>
  )
}

export const useSideNavContext = () => useContext(SideNavContext)
