import merge from 'lodash/merge'
import common from './config.common'
import production from './config.production'
import development from './config.development'
import type { Config } from './config.type'

const configs = {
  common,
  production,
  development,
  staging: development,
  preview: development,
}

export const env =
  import.meta.env.REACT_APP_NODE_ENV || import.meta.env.PROD
    ? 'production'
    : 'development'

export const isProd = () => env === 'production'

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

const getConf = () => {
  if (!(env in configs)) {
    return common
  }
  return configs[env]
}

const config: Config = merge(common, getConf())

export default config
