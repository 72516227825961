import { env } from '@/config'
import * as Sentry from '@sentry/react'
import { format, parseISO } from 'date-fns'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'
import React from 'react'
import { TimeFormats } from './common/datepicker/utilities/formats'
import { onCLS, onFID, onLCP, onFCP, onTTFB } from 'web-vitals'

const buildDate = __DATE__

// Report web vitals to Sentry
const reportWebVitals = (metric) => {
  // Log to console in development
  if (env === 'development') {
    console.log(metric)
    Sentry.captureMessage(
      `WebVitals: ${metric.name} - ${Math.round(metric.value * 10) / 10}`,
      {
        level: 'info',
        tags: {
          webVital: true,
          metricName: metric.name,
          metricValue: metric.value,
          metricRating: metric.rating,
        },
      }
    )
  }

  // Send to Sentry
}

// Initialize Sentry
Sentry.init({
  release: format(buildDate, TimeFormats.StandardDateMonthYearSmallHHmm),
  dsn: 'https://f869ca3eacd149fc8bda3fe432cb8c71@o142237.ingest.sentry.io/1202143',
  autoSessionTracking: true,
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: env,
  tracesSampleRate: 0.2,
  normalizeDepth: 10,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})

// Initialize web vitals monitoring
onCLS(reportWebVitals)
onLCP(reportWebVitals)
onFCP(reportWebVitals)
onTTFB(reportWebVitals)

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)
