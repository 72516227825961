// Sentry initialization should be imported first!
import './index.css'
import store from '@/state/store'
import Cookies from 'js-cookie'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { MotionGlobalConfig } from 'framer-motion'

import { sentryCreateBrowserRouter } from './instrument'
import { routes } from './routes'
import ReloadPrompt from './sw/reload-prompt'

// First, import performance utilities
import { initializePerformanceOptimizations } from './utils/performance'

// Initialize performance optimizations immediately to improve page loading
initializePerformanceOptimizations()

const router = sentryCreateBrowserRouter(routes as any)

const isLowPerformanceDevice = () => {
  // Check if device has less than 4 logical CPU cores
  const isIphone = /iPhone/i.test(navigator.userAgent)
  if (isIphone) {
    return false
  }

  const cpuCores = navigator.hardwareConcurrency || 1
  return cpuCores <= 4
}
MotionGlobalConfig.skipAnimations = isLowPerformanceDevice()

if (window.location.hostname === 'localhost') {
  //  document.domain = 'localhost'
}

// old site - when we are ready we will force people over to the new stampede site
if (window.location.hostname === 'product.blackbx.io') {
  window.location.href = `https://product.stampede.ai${window.location.pathname}${window.location.search}`
}

if (window.location.hostname.startsWith('connect')) {
  window.location.href = `https://${window.location.hostname.replace(
    'connect',
    'product'
  )}/${window.location.pathname}`
}

if (
  Cookies.get('theme') === 'dark'
    ? true
    : window.matchMedia('(prefers-color-scheme: dark)').matches
) {
  document.documentElement.classList.add('dark')
}

if ('standalone' in window.navigator && window.navigator.standalone) {
  // Check if running as PWA on iOS
  /*
  document.documentElement.style.overflow = 'hidden'
  document.body.style.overflow = 'hidden'


  document.body.addEventListener(
    'touchmove',
    function (e) {
      e.preventDefault()
    },
    { passive: false }
  )
    */
  document.body.style.position = 'fixed'
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.left = '0'
  document.body.style.right = '0'

  window.addEventListener('scroll', (e) => {
    // Check if any input, textarea, or contenteditable element is focused
    const activeElement = document.activeElement
    const isKeyboardOpen =
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      activeElement?.hasAttribute('contenteditable')

    // Only prevent scroll and reset position if keyboard is not open
    if (!isKeyboardOpen) {
      e.preventDefault()
      window.scrollTo(0, 0)
    }
  })
}

const createStore = store()

// Function to clean localStorage
function cleanLocalStorage() {
  try {
    const itemsToRemove: string[] = []

    // Iterate through all localStorage items
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (
        key &&
        !key.includes('user-credentials') &&
        !key.includes('user-config')
      ) {
        itemsToRemove.push(key)
      }
    }

    // Remove collected items
    itemsToRemove.forEach((key) => {
      localStorage.removeItem(key)
      console.log(`Cleaned up localStorage item: ${key}`)
    })
  } catch (error) {
    console.error('Error cleaning localStorage:', error)
  }
}

// Listen to storage events across tabs
window.addEventListener('storage', (event) => {
  console.log('Storage event:', event)
  if (event.key === 'syncData') {
    console.log('Storage event: Data synchronized across tabs:', event.newValue)
  }
})

// Set up periodic cleanup (runs every 5 minutes)
const CLEANUP_INTERVAL = 5 * 60 * 1000 // 5 minutes in milliseconds
setInterval(cleanLocalStorage, CLEANUP_INTERVAL)

// Run initial cleanup
cleanLocalStorage()

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ReloadPrompt />
    <ReduxProvider store={createStore}>
      <RouterProvider router={router} />
    </ReduxProvider>
  </StrictMode>
)
