// httpLoader.js
import { useState, useEffect } from 'react'

// --- Module-level state and subscriber management ---

// Global counter of active HTTP requests
let activeRequestCount = 0

// Array of subscriber callbacks
let subscribers: ((count: number) => void)[] = []

// Notify all subscribers of the current count
function notifySubscribers(): void {
  subscribers.forEach((callback) => callback(activeRequestCount))
}

// Increment the counter and notify subscribers
function incrementActiveRequests(): void {
  activeRequestCount++
  notifySubscribers()
}

// Decrement the counter and notify subscribers
function decrementActiveRequests(): void {
  activeRequestCount = Math.max(activeRequestCount - 1, 0)
  notifySubscribers()
}

// Allow subscribers (e.g., our hook) to get updates.
// The callback will be called immediately with the current count.
export function subscribeHttpLoader(
  callback: (count: number) => void
): () => void {
  subscribers.push(callback)
  // Send the current count right away
  callback(activeRequestCount)
  // Return an unsubscribe function
  return () => {
    subscribers = subscribers.filter((cb) => cb !== callback)
  }
}

// --- Patch the Global HTTP Methods (only once) ---

// Extend Window interface to include our custom property
declare global {
  interface Window {
    __httpLoaderPatched?: boolean
  }
}

// Use a flag on the window object to ensure we only patch once.
if (!window.__httpLoaderPatched) {
  window.__httpLoaderPatched = true

  // Patch XMLHttpRequest.send
  const originalXHRSend = XMLHttpRequest.prototype.send
  XMLHttpRequest.prototype.send = function (...args) {
    incrementActiveRequests()
    // Listen for the request to complete (whether success or error)
    this.addEventListener('loadend', decrementActiveRequests)
    return originalXHRSend.apply(this, args)
  }

  // Patch fetch
  const originalFetch = window.fetch
  window.fetch = function (
    ...args: Parameters<typeof fetch>
  ): Promise<Response> {
    incrementActiveRequests()
    return originalFetch
      .apply(this, args)
      .then((response) => {
        decrementActiveRequests()
        return response
      })
      .catch((error) => {
        decrementActiveRequests()
        throw error
      })
  }
}

// --- React Hook ---

export function useHttpLoader(): boolean {
  const [isLoading, setIsLoading] = useState(activeRequestCount > 0)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    // Subscribe to global HTTP loader updates
    const unsubscribe = subscribeHttpLoader((count) => {
      // If count becomes > 0, start the debounce timer
      if (count > 0) {
        // Only start a new timer if one isn't already running
        if (!timeoutId) {
          timeoutId = setTimeout(() => {
            setIsLoading(true)
          }, 500)
        }
      } else {
        // If count is 0, clear any pending timer and set loading to false
        if (timeoutId) {
          clearTimeout(timeoutId)
          timeoutId = null
        }
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    })

    // Cleanup subscription and any pending timeout on unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      unsubscribe()
    }
  }, [])

  return isLoading
}
