import {
  FormControl,
  Img,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  ArrowRightEndOnRectangleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid'
import { format, parseISO } from 'date-fns'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useRef } from 'react'
import { Input, SIconButton, Tooltip, cx } from 'tailwind-ui'
import Switch from 'tailwind-ui/src/forms/checkbox/switch'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { ScrollArea } from '@/common/scroll-area'
import useSelfServe from '@/components/self-serve/useSelfServe'
import { defaultServicePagination } from '@/connect-types/backend/service'
import { generateImage } from '@/helpers/image'
import { useNavigate } from 'react-router-dom'
import { setInitialSearch } from '@/state/global/global.actions'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { getFirst } from '@/utils/common'
import { useColorMode } from '@/hooks/use-color-mode'
import OrganisationsList from './organisations/organisations'
import Profile from './organisations/profile'
import OrganisationVenues from './organisations/venues'

const logoBase = 'https://assets.stampede.ai/logos/stampede-logomark-'
const logoBlack = `${logoBase}000000.png`
//const logoBlue = `${logoBase}1F2888.png`
const logoPink = `${logoBase}FF3A9B.png`
const logoWhite = `${logoBase}FFFFFF.png`

export type SearchType = 'organisations' | 'venues'
function Organisations() {
  const push = useNavigate()
  useColorMode()
  const nextRef = useRef<HTMLButtonElement | null>(null)
  const prevRef = useRef<HTMLButtonElement | null>(null)
  const searchRef = useRef<HTMLInputElement | null>(null)
  const search = useAppSelector((state) => state.global.pagination.search)
  const dispatch = useAppDispatch()
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const [searchType, setSearchType] = useQueryParam(
    'type',
    withDefault(StringParam, 'organisations')
  )
  const [cursor, setCursor] = useQueryParam(
    'cursor',
    withDefault(StringParam, '')
  )

  const selfserve = useSelfServe()

  const {
    data: organisations = { data: [] },
    isLoading: isOrganisationLoading,
    isFetching,
    isSuccess,
  } = morpheusApi.useGetOrganisationsSearchQuery(
    {
      search,
      limit: 20,
    },
    {
      skip: searchType !== 'organisations',
    }
  )

  const {
    data: venues = defaultServicePagination,
    isLoading: isVenueLoading,
    isFetching: isVenueFetching,
  } = morpheusApi.useGetLocationsQuery(
    {
      limit: 20,
      cursor,
      search,
    },
    {
      skip: searchType !== 'venues',
    }
  )

  const isLoading =
    isOrganisationLoading ||
    isVenueLoading ||
    selfserve.isLoading ||
    isFetching ||
    isVenueFetching

  useEffect(() => {
    if (!selfserve.data) return

    if (!selfserve.data.backend.organization_id) return
    push(`${selfserve.data.backend.organization_id}/self-serve`)
  }, [push, selfserve.data])

  useEffect(() => {
    if (search) return
    if (!isSuccess) return
    if (organisations.data.length !== 1) return

    push(`${getFirst(organisations.data)?.id}`)
  }, [organisations.data, push, search, isSuccess])

  const delayedSearchQuery = useRef(
    debounce((term: string) => {
      setCursor('')
      dispatch(setInitialSearch(term))
    }, 400)
  ).current

  useEffect(() => {
    if (!searchRef.current) return
    if (searchRef.current !== undefined) {
      searchRef.current.focus()
    }
  }, [])

  const selectedType = useMemo(() => {
    if (searchType === 'venues') {
      return venues
    }
    return organisations
  }, [organisations, searchType, venues])

  const logoMode = useColorModeValue(logoPink, logoWhite)

  return (
    <div className="relative h-full w-full overflow-hidden">
      <div className="absolute w-full h-full">
        <img
          alt="stampede-logo"
          className="h-full w-full object-cover"
          src={generateImage(
            'https://assets.stampede.ai/product/home/org-bg.png',
            'background'
          )}
        />
      </div>
      <div className="lg:p-2 relative z-10 flex h-full w-full overflow-hidden">
        <div className="space-y-1 flex-1  flex flex-col bg-white/80 dark:bg-black/80  pb-safe backdrop-blur-xl  lg:rounded-2xl z-10">
          <div className="flex justify-between px-4 lg:px-6 items-center pt-4">
            <Img
              alt="logo"
              h="30px"
              htmlHeight="30px"
              htmlWidth="111px"
              src={logoMode}
            />
            <div className="flex items-center gap-2">
              <Profile key="profile" />
              <Tooltip information="Logout">
                <SIconButton
                  aria-label="logout"
                  icon={ArrowRightEndOnRectangleIcon}
                  key="logout"
                  onClick={() => {
                    push('oauth/logout')
                  }}
                  isRound
                  variant="ghost_default"
                />
              </Tooltip>
            </div>
          </div>
          <div className="relative px-4 lg:px-6  py-2">
            <Input
              ref={searchRef}
              type="text"
              name="search"
              id="search"
              className="rounded-xl py-2 border-transparent border-0 focus:border-inherit focus:ring-0"
              defaultValue={search}
              onChange={({ target }) => {
                delayedSearchQuery(target.value)
              }}
              placeholder={`Search ${searchType}...`}
              rightElement={
                search ? (
                  <SIconButton
                    variant="ghost_default"
                    aria-label="clear"
                    icon={XMarkIcon}
                    onClick={() => {
                      if (!searchRef.current) return
                      searchRef.current.value = ''
                      delayedSearchQuery('')
                    }}
                    size="sm"
                    isLoading={isLoading}
                    isRound
                  />
                ) : null
              }
            />
          </div>
          <div className="flex flex-col flex-1 overflow-hidden space-y-4 ">
            <ScrollArea
              style={{ minHeight: 'auto' }}
              className="flex-1   rounded-xl  mx-4  min-h-fit  lg:mx-6"
            >
              {searchType === 'venues' ? (
                <OrganisationVenues search={search} />
              ) : (
                <OrganisationsList search={search} />
              )}
            </ScrollArea>

            <div
              className={cx(
                'grid  items-center justify-between px-4 lg:px-6 pb-4',
                {
                  'grid-cols-2': isMobile,
                  'grid-cols-3': !isMobile,
                }
              )}
            >
              <div className="flex flex- gap-1">
                <Switch
                  id="venues-alerts"
                  label={{
                    true: 'Organisations',
                    false: 'Venues',
                  }}
                  checked={searchType === 'venues'}
                  onChange={() => {
                    setCursor('')
                    setSearchType(
                      searchType === 'organisations'
                        ? 'venues'
                        : 'organisations'
                    )
                  }}
                />

                {!isMobile && <Footer />}
              </div>
              {'links' in selectedType && selectedType.links.next ? (
                <div className="flex gap-1 justify-self-end">
                  <SIconButton
                    variant="ghost_default"
                    aria-label="left"
                    isRound
                    className="bg-black/10 dark:bg-white/10  disabled:!bg-transparent"
                    icon={ChevronLeftIcon}
                    disabled={!selectedType.links.prev}
                    onClick={() => {
                      setCursor(selectedType.links.prev)
                    }}
                    ref={prevRef}
                  />
                  <SIconButton
                    variant="ghost_default"
                    isRound
                    className="bg-black/10 dark:bg-white/10 disabled:!bg-transparent"
                    aria-label="right"
                    icon={ChevronRightIcon}
                    disabled={!selectedType.links.next}
                    onClick={() => {
                      setCursor(selectedType.links.next)
                    }}
                    ref={nextRef}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Organisations
const buildDate = __DATE__

function Footer() {
  return (
    <div className="bg-white/20 dark:bg-black/20 rounded-lg p-1 px-2 inline-block">
      <p className="text-xs text-black/50 dark:text-white/50 font-bold">
        Build Time{' '}
        {format(buildDate, TimeFormats.StandardDateMonthYearSmallHHmm)}
      </p>
    </div>
  )
}
