import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const usePathname = () => {
  const { org_id } = useParams()
  const { pathname } = useLocation()
  return `${pathname.replace(`/${org_id}`, '')}`
}

function useSaveLastRoute() {
  const { pathname } = useLocation()

  useEffect(() => {
    console.log('use_effect_save_last_route')
    const excludedPaths = ['/', 'oauth', 'code']
    const shouldSave = !excludedPaths.some((path) => pathname.includes(path))

    if (shouldSave) {
      localStorage.setItem('last-visited-pathname', pathname)
    } else if (pathname !== '/') {
      localStorage.removeItem('last-visited-pathname')
    }
  }, [pathname])
}

export default useSaveLastRoute
