import { AvailableRoles } from '@/state/auth/auth.types'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import { setOrgId } from '@/state/global/global.actions'
import { useAppDispatch } from '@/state/helpers/useApp'
import { getFirst } from '@/utils/common'
import { usePusherSubscription } from '@/utils/pusher'
import { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { BrandKitStyles, cn, cx, Portal, Spinner, Tooltip } from 'tailwind-ui'
// import { customerApi } from './customer/:id/layout'
import { ScrollArea } from '@/common/scroll-area'
import Blurrer from '@/components/Pii/Blurrer'
import { useAlertContext } from '@/components/alerts'
import { InboxModalProvider } from '@/components/inbox/context/modal'
import { zEOpen } from '@/helpers/zendesk'
import useSubscription from '@/hooks/useSubscription'
import useUser, { useUserRole } from '@/hooks/useUser'
import { UserInviteProvider } from '@/providers/user-invite.context'
import { LegacyPlanTypes } from '@/state/billing/billing.types'
import inboxApi from '@/state/inbox/inbox.slice'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import { usePageViewLogger } from '@/utils/tracking'
import BubbleLoad from '@/ux/bubble-load'
import { AnimatePresence, motion } from 'framer-motion'
import { useHttpLoader } from '@/utils/use-http-loader'
import { AnimatedSidebar } from './sidebar'
import { useSideNavContext } from '@/utils/router/sidenav-provider'

//import style from './app.module.css'

const showNavOn = [
  '/',
  '/venues',
  '/marketing',
  '/reviews',
  '/gifting',
  '/loyalty',
  '/reports',
  '/bookings',
  '/venues/:serial/booking',
  '/venues/:serial/loyalty',
  '/venues/:serial/bookings/menus/items',
  '/venues/:serial/bookings/menus/modifiers',
  '/venues/:serial/bookings/menus/modifier-categories',
]

export const globalHideNavOn = [
  '/workflows/:id/*',
  '/marketing/email/campaigns/:id',
  '/marketing/email/templates/:id',
  '/venues/:serial/loyalty/:id/service/*',
  // '/venues/:serial/loyalty/:id/customers/:customer_id',
  '/venues/:serial/bookings/day-planner',
  '/venues/:serial/bookings/list',
  '/venues/:serial/bookings/floor-plans-edit',
  '/venues/:serial/bookings/floor-plans',
  '/venues/:serial/bookings/menus/:id/*',
]

function DashLayout() {
  const { pathname } = useLocation()

  // const shouldHaveApp = useShouldHaveApp()
  const organisation = useSelectedOrganisation()
  const user = useUser()
  const role = useUserRole()
  const push = useNavigate()
  const dispatch = useAppDispatch()
  const subscription = useSubscription()
  const [alerts, setAlerts] = useAlertContext()

  const [isOrgLoadingSlow, setIsOrgLoadingSlow] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (!organisation) {
      // Start timing when organization is null
      timeoutId = setTimeout(() => {
        setIsOrgLoadingSlow(true)
      }, 5000) // 5 seconds
    }

    return () => {
      // Clean up timeout if organization loads before 5 seconds
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [organisation])

  usePageViewLogger()
  const hiddenOn = useMemo(() => {
    const hideMatch =
      globalHideNavOn
        .map((item) => matchPath(`/:org_id${item}`, pathname))
        .filter((item) => Boolean(item)).length > 0

    const showMatch =
      showNavOn
        .map((item) => matchPath(`/:org_id${item}`, pathname))
        .filter((item) => Boolean(item)).length > 0

    return hideMatch && !showMatch
  }, [pathname, globalHideNavOn, showNavOn])

  const { org_id } = useParams<{ org_id: string }>()

  useEffect(() => {
    if (!org_id) return
    dispatch(setOrgId(org_id))
  }, [org_id, dispatch])

  useEffect(() => {
    console.log('use_effect_push_to_bookings', pathname)
    if (!role) return
    if (pathname.includes('bookings')) return
    if (role.name !== AvailableRoles.Booking) return
    if (!organisation?.locations) return
    const location = getFirst(organisation.locations)
    if (location && matchPath(':org_id/', pathname)) {
      push(`venues/${location.serial}/bookings`)
    }
  }, [role, pathname, push, organisation])

  usePusherSubscription<{ open: number; closed: number }>(
    'inbox.thread.unread_count.updated',
    (data) => {
      console.log('pusher_inbox_thread_unread_count_updated', data)
      dispatch(
        inboxApi.util.invalidateTags([
          { type: 'counts', id: 'LIST' },
          { type: 'counts' }, // Invalidate all counts
          { type: 'threads', id: 'LIST' },
          { type: 'threads' },
        ])
      )
    }
  )

  const isSubscriptionTrial = useMemo((): boolean => {
    // return true
    if (!subscription) return false
    if (subscription.legacy_plan === LegacyPlanTypes.SelfServe) {
      return false
    }
    return subscription.subscription.is_trial
  }, [subscription])

  useEffect(() => {
    if (!isSubscriptionTrial) return
    setAlerts({
      itemKey: 'trial',
      type: 'success',
      title: `You are currently in trial. Be sure to add a payment method for
      when your account rolls over`,
      to: `organisation/billing`,
      isDismissible: true,
    })
  }, [isSubscriptionTrial, setAlerts])

  useEffect(() => {
    if (!subscription) return
    if (subscription?.needs_contact_upgrade > 0) {
      setAlerts({
        itemKey: 'contacts',
        type: 'warning',
        title: `Additional ${subscription?.needs_contact_upgrade} contacts required, upgrade to continue using marketing, wifi and inbox`,
        onClick: zEOpen,
      })
    }
    if (subscription?.needs_venue_upgrade > 0) {
      setAlerts({
        itemKey: 'venue',
        title: `Additional ${subscription?.needs_venue_upgrade} venues required, upgrade to continue using wifi, reviews and marketing`,

        type: 'warning',
        onClick: zEOpen,
      })
    }
  }, [subscription, setAlerts])

  const isSubscriptionActive = useMemo((): boolean => {
    // for legacy orgs without a subscription record.
    // return false
    if (!subscription) {
      return true
    }

    return subscription.active
  }, [subscription])

  useEffect(() => {
    if (isSubscriptionActive) {
      setAlerts({ itemKey: 'subscription', isDismissed: true })
      return
    }

    setAlerts({
      itemKey: 'subscription',
      title: `Your account is suspended due to overdue payment. Activate your subscription by making payment now`,
      type: 'error',
      to: `organisation/billing`,
    })
  }, [isSubscriptionActive])

  const config = useUserConfig()

  const branding = useMemo(() => {
    if (!organisation?.locations) return null
    const locationsWithBranding = organisation.locations.filter(
      (item) => item?.branding_settings.is_default
    )
    if (locationsWithBranding.length === 0) return null

    return getFirst(locationsWithBranding)?.branding_settings
  }, [organisation])

  const isHttpLoading = useHttpLoader()
  const [{ isMainNavCollapsed }] = useSideNavContext()
  if (!organisation || !user) {
    return (
      <BubbleLoad
        text={
          isOrgLoadingSlow
            ? 'This is taking longer than usual, sit tight...'
            : 'Loading account...'
        }
      />
    )
  }
  console.log({ config })

  return (
    <UserInviteProvider key="UserInviteProvider">
      <Portal className="fixed left-0.5 top-0.5 z-50  flex items-center justify-center">
        <motion.div
          className="flex items-center justify-center bg-green-500 rounded-full p-0 size-4"
          initial="idle"
          exit="idle"
          animate={isHttpLoading ? 'loading' : 'idle'}
          variants={{
            idle: { scale: 0 },
            loading: { scale: 1 },
          }}
          transition={{ duration: 0.5 }}
        >
          <Spinner
            size="xs"
            brandKit={false}
            className="text-green-500 fill-green-900/50"
          />
        </motion.div>
      </Portal>
      {branding ? <BrandKitStyles ignoreBody brand={branding} /> : null}
      <InboxModalProvider>
        <div className="h-full w-full flex ">
          {!hiddenOn && <AnimatedSidebar />}
          <div
            className={cn('flex-1  overflow-auto flex flex-col', {
              'max-md:pb-16': isMainNavCollapsed && !hiddenOn,
            })}
          >
            <Outlet />
          </div>
        </div>

        <Blurrer />
      </InboxModalProvider>
    </UserInviteProvider>
  )
}

export default DashLayout
