import config from '@/config'
import { useLocalStorage } from 'usehooks-ts'

export const getToken = (): { accessToken: string } | null => {
  const item = localStorage.getItem(
    `${config.localStorageKeys.userCredentials}_${import.meta.env.PROD ? 'prd' : 'stg'}`
  )

  if (!item) {
    return null
  }

  return JSON.parse(item)
}

export const useAccessToken = () => {
  const [token] = useLocalStorage<{ accessToken: string }>(
    `${config.localStorageKeys.userCredentials}_${import.meta.env.PROD ? 'prd' : 'stg'}`,
    { accessToken: '' }
  )
  return token
}

export const setToken = (token: { accessToken: string }) => {
  localStorage.setItem(
    `${config.localStorageKeys.userCredentials}_${import.meta.env.PROD ? 'prd' : 'stg'}`,
    JSON.stringify(token)
  )
}

export interface ApiUserRefreshTokenResponse {
  access_token?: string
  expires_in?: number
  refresh_token?: string
  scope?: null // FIXME: what type should this be
  token_type?: 'Bearer'
  error?: string
  error_description?: string
}

export interface ApiUserRefreshTokenRequestData {
  client_id: string
  client_secret: string
  grant_type: 'refresh_token'
  refresh_token: string
  scope?: string
}

export const refresh_client = (
  refresh_token: string
): ApiUserRefreshTokenRequestData => ({
  client_id: config.auth.clientId,
  client_secret: config.auth.clientSecret,
  grant_type: 'refresh_token',
  refresh_token,
  scope: config.auth.scope,
})
