import { Calendar } from '@/shadcn/ui/calendar'
import { useGlobalQuery } from '@/state/global/global.hooks'
import {
  FormControl,
  FormLabel,
  HStack,
  Switch,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react'
import { CalendarDaysIcon, ChevronDownIcon } from '@heroicons/react/16/solid'
import {
  addMonths,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  isSameDay,
  startOfYear,
  startOfMonth,
  subDays,
  subYears,
  endOfQuarter,
  startOfQuarter,
  subQuarters,
  subMonths,
  subWeeks,
  endOfWeek,
  startOfWeek,
  startOfDay,
} from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, SButton } from 'tailwind-ui'
import { TimeFormats } from './utilities/formats'
import MonthPicker from './utilities/month.picker'
import DateLitePicker from './utilities/picker'
import Drawer from '@/pages/[org_id]/venues/[serial]/bookings/components/drawer'

const rangePresets: Record<
  'daily' | 'monthly' | 'yearly' | 'quarterly' | 'weekly',
  { start: Date; end: Date; label: string }[]
> = {
  daily: [],
  weekly: [
    {
      label: 'This Week',
      start: startOfWeek(new Date()),
      end: subDays(new Date(), 1),
    },
    {
      label: 'Last Week',
      start: startOfWeek(subWeeks(new Date(), 1)),
      end: endOfWeek(subWeeks(new Date(), 1)),
    },
    {
      label: '2 Weeks Ago',
      start: startOfWeek(subWeeks(new Date(), 2)),
      end: endOfWeek(subWeeks(new Date(), 2)),
    },
    {
      label: '3 Weeks Ago',
      start: startOfWeek(subWeeks(new Date(), 3)),
      end: endOfWeek(subWeeks(new Date(), 3)),
    },
  ],
  monthly: [
    {
      label: 'This Month',
      start: startOfMonth(new Date()),
      end: subDays(new Date(), 1),
    },
    {
      label: 'Last Month',
      start: startOfMonth(subMonths(new Date(), 1)),
      end: endOfMonth(subMonths(new Date(), 1)),
    },
    {
      label: '2 Months Ago',
      start: startOfMonth(subMonths(new Date(), 2)),
      end: endOfMonth(subMonths(new Date(), 2)),
    },
    {
      label: '3 Months Ago',
      start: startOfMonth(subMonths(new Date(), 3)),
      end: endOfMonth(subMonths(new Date(), 3)),
    },
  ],
  quarterly: [
    {
      label: 'This Quarter',
      start: startOfQuarter(new Date()),
      end: subDays(new Date(), 1),
    },
    {
      label: 'Last Quarter',
      start: startOfQuarter(subQuarters(new Date(), 1)),
      end: endOfQuarter(subQuarters(new Date(), 1)),
    },
    {
      label: '2 Quarters Ago',
      start: startOfQuarter(subQuarters(new Date(), 2)),
      end: endOfQuarter(subQuarters(new Date(), 2)),
    },
    {
      label: '3 Quarters Ago',
      start: startOfQuarter(subQuarters(new Date(), 3)),
      end: endOfQuarter(subQuarters(new Date(), 3)),
    },
  ],
  yearly: [
    {
      label: 'This Year',
      start: startOfYear(new Date()),
      end: subDays(new Date(), 1),
    },
    {
      label: 'Last Year',
      start: startOfYear(subYears(new Date(), 1)),
      end: endOfYear(subYears(new Date(), 1)),
    },
    {
      label: '2 Years Ago',
      start: startOfYear(subYears(new Date(), 2)),
      end: endOfYear(subYears(new Date(), 2)),
    },
    {
      label: '3 Years Ago',
      start: startOfYear(subYears(new Date(), 3)),
      end: endOfYear(subYears(new Date(), 3)),
    },
  ],
}

function DatePickerSelector() {
  const [query, setQuery] = useGlobalQuery()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <DatePickerSelectorControlled
      dates={[new Date(query.startDate), new Date(query.endDate)]}
      onChange={([startDate, endDate]) => {
        setQuery({
          startDate: startDate.getTime(),
          endDate: endOfDay(endDate).getTime(),
        })
      }}
    />
  )
}

interface PassedProps {
  dates: [Date, Date]
  onChange: (dates: [Date, Date]) => void
  allTime?: boolean
  isExclusive?: boolean
  minDate?: Date
}

export function DatePickerSelectorControlled({
  dates,
  onChange,
  minDate = null,
}: PassedProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [start, end] = dates
  const [shallowDates, setShallowDates] = useState(dates)

  useEffect(() => {
    setShallowDates(dates)
  }, [dates])
  const selectedRange = useMemo(() => {
    const [shallowStart, shallowEnd] = shallowDates
    if (!shallowEnd) {
      return 'daily'
    }
    if (isSameDay(shallowStart, shallowEnd)) {
      return 'daily'
    }
    const intervals = eachDayOfInterval({
      start: shallowStart,
      end: shallowEnd,
    })
    if (intervals.length >= 160) {
      return 'yearly'
    }
    if (intervals.length >= 32) {
      return 'quarterly'
    }
    if (intervals.length >= 20) {
      return 'monthly'
    }
    if (intervals.length >= 7) {
      return 'weekly'
    }
    return 'daily'
  }, [shallowDates, shallowDates])

  console.log({ selectedRange })
  const [shallowRange, setShallowRange] =
    useState<typeof selectedRange>(selectedRange)

  return (
    <>
      <Drawer
        title={format(shallowDates[0], 'MMMM yy')}
        open={isOpen}
        setOpen={setIsOpen}
        size="sm"
      >
        <div className="flex flex-col gap-4 justify-between flex-1 h-full">
          <div className="space-y-0">
            <Calendar
              month={shallowDates[0]}
              //disableNavigation={false}
              // showOutsideDays={false}
              //excludeDisabled

              classNames={{
                month_caption: 'hidden',
                nav: 'absolute top-2 right-2',
                months: 'initial',
              }}
              disabled={{
                after: subDays(new Date(), 1),
              }}
              selected={{
                from: shallowDates[0],
                to: shallowDates[1],
              }}
              onMonthChange={(ev) => {
                setShallowRange('monthly')
                setShallowDates([startOfMonth(ev), endOfMonth(ev)])
              }}
              onSelect={(dd) => {
                if (dd?.from && dd?.to) {
                  setShallowDates([startOfDay(dd.from), endOfDay(dd.to)])
                }
              }}
              mode="range"
            />
            <div className="space-x-0.5 border-y border-black/5 dark:border-white/5 flex items-center bg-neutral-200 dark:bg-neutral-800 justify-evenly p-1">
              <SButton
                variant="ghost_default"
                isActive={shallowRange === 'weekly'}
                onClick={() => {
                  setShallowRange('weekly')
                  setShallowDates([
                    rangePresets.weekly[0].start,
                    rangePresets.weekly[0].end,
                  ])
                }}
              >
                Week
              </SButton>
              <SButton
                variant="ghost_default"
                isActive={shallowRange === 'monthly'}
                onClick={() => {
                  setShallowRange('monthly')
                  setShallowDates([
                    rangePresets.monthly[0].start,
                    rangePresets.monthly[0].end,
                  ])
                }}
              >
                Month
              </SButton>

              <SButton
                variant="ghost_default"
                isActive={shallowRange === 'quarterly'}
                onClick={() => {
                  setShallowRange('quarterly')
                  setShallowDates([
                    rangePresets.quarterly[0].start,
                    rangePresets.quarterly[0].end,
                  ])
                }}
              >
                Quarter
              </SButton>
              <SButton
                variant="ghost_default"
                isActive={shallowRange === 'yearly'}
                onClick={() => {
                  setShallowRange('yearly')
                  setShallowDates([
                    rangePresets.yearly[0].start,
                    rangePresets.yearly[0].end,
                  ])
                }}
              >
                Year
              </SButton>
            </div>

            <div className="flex flex-col space-y-1 px-4 py-4">
              {(rangePresets[shallowRange] ?? []).map((item) => (
                <SButton
                  key={item.label}
                  className="rounded-lg border-none"
                  isActive={
                    shallowDates[0]?.getTime() === item.start.getTime() &&
                    shallowDates[1].getTime() === item.end.getTime()
                  }
                  onClick={() => {
                    setShallowDates([item.start, item.end])
                  }}
                >
                  {item.label}
                </SButton>
              ))}
            </div>
          </div>
          <div className="p-4 pb-2 w-full space-y-1">
            <SButton
              size="lg"
              variant="primary"
              className="rounded-lg w-full"
              onClick={() => {
                setIsOpen(false)
                onChange(shallowDates)
              }}
            >
              Select Dates
            </SButton>

            <p className="py-1 text-center text-xs text-neutral-400 dark:text-neutral-500">
              {shallowDates.length > 1 &&
                shallowDates[0] &&
                format(
                  shallowDates[0],
                  TimeFormats.StandardDateAndDayMonthYear
                )}{' '}
              -{' '}
              {shallowDates.length > 1 &&
                shallowDates[1] &&
                format(
                  shallowDates[1],
                  TimeFormats.StandardDateAndDayMonthYear
                )}
            </p>
          </div>
        </div>
      </Drawer>
      <SButton
        leftIcon={CalendarDaysIcon}
        onClick={() => {
          setIsOpen(true)
        }}
        rightIcon={ChevronDownIcon}
        variant="ghost_default"
        className="h-10"
      >
        {format(start, TimeFormats.StandardDateMonthYear)} {' — '}
        {format(end, TimeFormats.StandardDateMonthYear)}
      </SButton>
    </>
  )
}

export function MonthPickerSelector({
  dates,
  onChange,
  allTime = false,
  isExclusive = false,
}: PassedProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectFullYear, setSelectFullYear] = useBoolean(false)
  const [start, end] = dates
  const isAll = useMemo(() => {
    if (!allTime) return false
    const allStart = subYears(startOfMonth(new Date()), 7)
    return allStart.getTime() === start.getTime()
  }, [allTime, start])

  const formatEnd = useCallback(
    (startDate: Date, endDate: Date) => {
      if (selectFullYear) return endDate
      return isExclusive
        ? startOfMonth(addMonths(startDate, 1))
        : endOfMonth(startDate)
    },
    [isExclusive, selectFullYear]
  )

  return (
    <>
      <Modal
        title="Select year/month"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <div className="flex flex-col gap-4">
          <MonthPicker
            dates={dates}
            onChange={([startDate, endDate]) => {
              setIsOpen(false)
              onChange([startDate, formatEnd(startDate, endDate)])
            }}
          />
          <FormControl as={HStack} justify="space-between">
            <FormLabel mb={0}>Select full year</FormLabel>
            <Switch
              isChecked={selectFullYear}
              onChange={setSelectFullYear.toggle}
            />
          </FormControl>
          {allTime ? (
            <div className="self-center">
              <SButton
                isActive={isAll}
                leftIcon={CalendarDaysIcon}
                onClick={() => {
                  setIsOpen(false)
                  onChange([
                    subYears(startOfMonth(new Date()), 7),
                    startOfMonth(new Date()),
                  ])
                }}
              >
                All-time
              </SButton>
            </div>
          ) : null}
        </div>
      </Modal>
      <SButton
        leftIcon={CalendarDaysIcon}
        onClick={() => {
          setIsOpen(true)
        }}
        rightIcon={ChevronDownIcon}
        variant="ghost_default"
      >
        {!selectFullYear && !isAll && format(start, TimeFormats.DateYear)}
        {selectFullYear && !isAll ? (
          <>
            {format(start, TimeFormats.DateYear)} {' — '}
            {format(end, TimeFormats.DateYear)}
          </>
        ) : null}
        {isAll ? <>All-time</> : null}
      </SButton>
    </>
  )
}

export default DatePickerSelector
