import { CancelReason } from '@/connect-types/members/cancel.types'
import {
  GalleryGetResponse,
  GalleryItem,
} from '@/connect-types/members/galleryGetResponse.type'
import { User } from '@/connect-types/user/user.type'
import { createAction, createAsyncAction } from 'typesafe-actions'
import { OrganisationLabelType } from '../organisations/organisations.types'

/**
 * GET Get Images belonging to a User
 */
export const membersGalleryContentRequest = createAction(
  'MEMBERS_GALLERY_CONTENT_REQUEST',
  (resolve) =>
    (
      orgId: string,
      offset: number,
      kind?: string,
      path?: string,
      label_like?: string,
      name_like?: string
    ) =>
      resolve({ orgId, offset, kind, path, label_like, name_like })
)

export const membersGalleryContentRequestSuccess = createAction(
  'MEMBERS_GALLERY_CONTENT_REQUEST_SUCCESS',
  (resolve) => (gallery: GalleryGetResponse, kind?: string, path?: string) =>
    resolve({ gallery, kind, path })
)

export const membersGalleryContentRequestFailure = createAction(
  'MEMBERS_GALLERY_CONTENT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const membersGalleryPushContent = createAction(
  'SET_MEMBERS_GALLERY_CONTENT',
  (resolve) => (item: GalleryItem) => resolve({ item })
)

/**
 * DELETE Delete a piece of content
 *
 * Path: /members/{uid}/gallery/{id}
 */

// FIXME: add in request and query params
export const deleteMembersGalleryContentRequest = createAction(
  'DELETE_MEMBERS_GALLERY_CONTENT_REQUEST',
  (resolve) => (orgId: string, id: number) => resolve({ orgId, id })
)

// FIXME: add in response object from saga
export const deleteMembersGalleryContentRequestSuccess = createAction(
  'DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_SUCCESS',
  (resolve) => (id: number) => resolve({ id })
)

export const deleteMembersGalleryContentRequestFailure = createAction(
  'DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * DELETE Get/delete a template
 *
 * Path: /members/{uid}/templates/{id}
 */

/**
 * DELETE
 *
 * Path: /members/{uid}/notifications
 */

// FIXME: add in request and query params

export const sendSupportEmailRequest = createAction(
  'SEND_SUPPORT_EMAIL_REQUEST',
  (resolve) => (request: CancelReason) => resolve({ request })
)

export const sendSupportEmailRequestSuccess = createAction(
  'SEND_SUPPORT_EMAIL_REQUEST_SUCCESS',
  (resolve) => (response: any) => resolve({ response })
)

export const sendSupportEmailRequestFailure = createAction(
  'SEND_SUPPORT_EMAIL_REQUEST_FAILURE',
  (resolve) => () => resolve()
)

export const addTagToImage = createAction(
  'ADD_TAG_TO_IMAGE',
  (resolve) => (imageId: string, label: OrganisationLabelType) =>
    resolve({ imageId, label })
)

export const removeTagToImage = createAction(
  'REMOVE_TAG_TO_IMAGE',
  (resolve) => (imageId: string, label: OrganisationLabelType) =>
    resolve({ imageId, label })
)
