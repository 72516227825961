import { ScrollArea } from '@/common/scroll-area'
import { usePageTitle } from '@/hooks/usePageTitle'
import useWindowWidth from '@/hooks/useWindowWidth'
import { navButtonClasses } from '@/pages/[org_id]/sidebar'
import { useSideNavContext } from '@/utils/router/sidenav-provider'
import { useGoBack } from '@/utils/router/useGoBack'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/16/solid'
import { AnimatePresence, motion } from 'framer-motion'
import {
  type ReactElement,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  matchPath,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { cn, Icn, Portal, SIconButton } from 'tailwind-ui'
import Heading from 'tailwind-ui/src/typeography/heading'
import { Breadcrumbs } from './breadcrumbs'
import { globalHideNavOn } from '@/pages/[org_id]/layout'

export interface LinkType {
  value: string
  key?: string
  hidden?: boolean
  icon?:
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string | undefined
          titleId?: string | undefined
        } & React.RefAttributes<SVGSVGElement>
      >
    | string
  children?: LinkType[]
}

const nestedLength = (link: LinkType) => {
  if (link.children) {
    return link.children.filter((child) => !child.children).length
  }
  return 0
}

const RenderLinks = ({
  links,
  level = 0,
  root = '',
}: {
  links: LinkType[]
  level?: number
  root?: string
}) => {
  const [{ isSecondaryNavCollapsed }] = useSideNavContext()

  const [collapsed, setCollapsed] = useState<string[]>([])

  return (
    <>
      {links.map((link, index) => {
        // Skip hidden links
        if (link.hidden) return null
        const path = root ? root + '/' + link.key : link.key
        return (
          <div key={link.key ?? index} className="flex flex-col">
            {level === 0 && link.children ? (
              <p className="text-sm text-neutral-500 font-semibold dark:text-neutral-300  py-2">
                {link.value}
              </p>
            ) : (
              <NavLink
                end
                {...(level >= 1 && {
                  onClick: () => {
                    setCollapsed((s) => {
                      if (s.includes(path)) {
                        return s.filter((k) => k !== path)
                      }
                      return [...s, path]
                    })
                  },
                })}
                to={path?.endsWith('/') ? path.slice(0, -1) : path}
                className={({ isActive }) => {
                  const active = link.key?.includes('#')
                    ? isActive &&
                      link.key.endsWith(window.location.hash) &&
                      isActive
                    : isActive

                  return cn(
                    navButtonClasses({
                      isCollapsed: isSecondaryNavCollapsed,
                      isActive: active,
                    }),
                    'capitalize'
                  )
                }}
              >
                {typeof link.icon !== 'string' && link.icon ? (
                  <Icn
                    className="size-5 mr-2 group-[.active]:text-stampede-500 dark:group-[.active]:text-stampede-500 fill-current"
                    icon={link.icon}
                  />
                ) : undefined}
                {typeof link.icon === 'string' ? (
                  <div className="w-6 h-6 mr-2 flex dark:bg-white/80 bg-black/80 rounded-xl p-0.5 items-center">
                    <img
                      className="object-cover h-full w-full rounded-md"
                      src={link.icon}
                      alt={link.value}
                    />
                  </div>
                ) : undefined}
                <span className="truncate overflow-hidden text-neutral-700 dark:text-neutral-300">
                  {link.value}
                </span>

                {/* Add indicator for items with children */}
                {level > 0 &&
                  nestedLength(link) > 1 &&
                  !isSecondaryNavCollapsed && (
                    <Icn
                      icon={
                        collapsed.includes(path)
                          ? ChevronRightIcon
                          : ChevronDownIcon
                      }
                      className="ml-auto h-4 w-4 text-neutral-400"
                    />
                  )}
              </NavLink>
            )}

            {/* Recursively render children */}
            <AnimatePresence mode="wait">
              {!collapsed.includes(path) &&
                link.children &&
                link.children.length > 0 &&
                !isSecondaryNavCollapsed && (
                  <motion.div
                    className="mt-1 mb-1  space-y-1"
                    key={path}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <RenderLinks
                      root={root ? root + '/' + link.key : link.key}
                      links={link.children}
                      level={level + 1}
                    />
                  </motion.div>
                )}
            </AnimatePresence>
          </div>
        )
      })}
    </>
  )
}

// Create a ConditionalPortal component
export const ConditionalPortal = ({
  children,
  condition,
  className,
}: {
  children: ReactNode
  condition: boolean
  className?: string
}) => {
  return condition ? (
    <Portal className={className}>{children}</Portal>
  ) : (
    <div className={className}>{children}</div>
  )
}

function TabbedNavLayout({
  links = [],
  title = '',
  isRootOutlet = false,
  context,
  rightElement,
  backTo = undefined,
  children,
}: {
  links: LinkType[]
  title: string
  backTo?: string | number
  isRootOutlet?: boolean
  context?: unknown
  rightElement?: ReactElement

  children?: ReactNode
  idMap?: Record<string, string>
}) {
  const location = useLocation()

  const [
    { isSecondaryNavCollapsed, isMainNavCollapsed, isNavCollapsed },
    setNavState,
  ] = useSideNavContext()

  const { width } = useWindowWidth()

  useEffect(() => {
    setNavState((prev) => ({
      ...prev,
      isSecondaryNavCollapsed: isRootOutlet,
      isMainNavCollapsed: true,
      hasSecondaryNav: !isRootOutlet,
    }))

    return () => {
      setNavState((prev) => ({
        ...prev,
        hasSecondaryNav: false,
        isSecondaryNavCollapsed: true,
        isMainNavCollapsed: false,
      }))
    }
  }, [isRootOutlet])

  usePageTitle(title)

  const goBack = useGoBack({
    backTo: !backTo ? undefined : backTo.toString(),
  })
  const match = globalHideNavOn
    .map((item) => matchPath(`/:org_id${item}`, location.pathname))
    .filter((item) => Boolean(item))

  if (isRootOutlet || match.length > 0) {
    return <Outlet context={context} />
  }

  if (isNavCollapsed) {
    return <Outlet context={context} />
  }

  return (
    <>
      <Portal
        className={cn(
          ' absolute transition-all  duration-200 z-50 m-2 h-full bottom-0 top-0 h-auto md:border-l border-black/5 dark:border-white/5',
          {
            'w-[48px]': isSecondaryNavCollapsed,
            'w-[calc(100%-4.9rem)] md:w-[292px] max-md:right-2  z-50':
              !isSecondaryNavCollapsed,
            'border-l': width < 768 && !isSecondaryNavCollapsed,
            'left-16 ': isMainNavCollapsed && width >= 768,
            'left-0': isMainNavCollapsed && width < 768,
            'left-16':
              isMainNavCollapsed && width < 768 && !isSecondaryNavCollapsed,
            'left-[292px]': !isMainNavCollapsed,
          }
        )}
      >
        <ScrollArea
          className={cn(
            'size-full w-fit  overflow-hidden',
            'w-full flex flex-col inset-0  absolute max-md:bottom-0 transition-colors duration-300 transform z-50 md:z-20 hover-scroll',
            {
              'pt-16': isSecondaryNavCollapsed,
            }
          )}
        >
          <div
            className={cn(
              'flex border-b border-black/5 dark:border-white/5 py-2 pr-2 justify-between  items-start relative',
              {
                'pl-2 ': isSecondaryNavCollapsed,
                // 'p-2': isSecondaryNavCollapsed,
                //'items-center md:flex-col self-center': !debouncedSidebarState,
                //  'mt-12': isSecondaryNavCollapsed,
              }
            )}
          >
            {isSecondaryNavCollapsed ? null : (
              <div className={cn('flex flex-col mt-1.5 pl-4 ')}>
                <Heading className="truncate text-sm ">{title}</Heading>

                <Breadcrumbs isSlim />
              </div>
            )}
          </div>
          {isSecondaryNavCollapsed ? (
            <div className="flex flex-col max-md:hidden">
              <p className="text-sm font-bold text-neutral-500 dark:text-neutral-400 px-4 py-2  text-nowrap rotate-90">
                {title}
              </p>
            </div>
          ) : null}
          <AnimatePresence mode="wait">
            {isSecondaryNavCollapsed ? null : (
              <motion.nav
                key="nav-area"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.4 } }}
                exit={{ opacity: 0, transition: { duration: 0 } }}
                className={cn(
                  'flex flex-1 py-2 flex-col justify-between space-y-4'
                )}
                aria-label="Tabs"
                id="nav-area"
              >
                <div
                  className={cn(' flex-1 px-4 pb-6 flex flex-col w-full gap-1')}
                >
                  <RenderLinks links={links} level={0} />

                  {rightElement ? (
                    <div className="lg:hidden flex items-center gap-2">
                      {rightElement}
                    </div>
                  ) : null}
                </div>
              </motion.nav>
            )}
          </AnimatePresence>
          {children && !isSecondaryNavCollapsed && (
            <div key={location.key} className={cn('mx-4 pb-4')}>
              {children}
            </div>
          )}
        </ScrollArea>
      </Portal>

      <Outlet context={context} />
    </>
  )
}

export default TabbedNavLayout
