import type { MenuType } from '@/components/SideNav/menu-config'
import type { OrganisationLocationType } from '@/connect-types/organisations/organisations.type'
import { useSelectedVenue } from '@/state/backend/venues/venues.hooks'
import type { SecondNavMenuItemType } from '@/state/entities/ui/ui.types'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useLocations from '../state/entities/locations/hooks/useLocation'

const useVenue = () => {
  const { serial } = useParams<{ serial: string }>()

  const selectedLocation = useSelectedVenue(serial)

  return selectedLocation.data
}

export const useVenues = () => {
  const venues = useLocations()

  const initialState: Record<string, OrganisationLocationType> = {}
  const keyedVenues = useMemo(
    () =>
      venues.reduce(
        (obj, item) => ({
          ...obj,
          [item.serial]: item,
        }),
        initialState
      ),
    [venues]
  )

  const findVenue = useCallback(
    (serial: string = '') => {
      if (serial in keyedVenues) {
        return keyedVenues[serial]
      }
      return null
    },
    [keyedVenues]
  )

  const findAlias = useCallback(
    (serial: string = '') => {
      const venue = findVenue(serial)
      if (!venue) return serial

      return venue.alias || serial
    },
    [findVenue]
  )

  const setVenue = useCallback(
    (venue: OrganisationLocationType) => {
      const id = venues.findIndex(
        (location) => location.serial === venue.serial
      )

      venues[id] = venue
    },
    [venues]
  )

  return [venues, findAlias, setVenue, keyedVenues] as const
}

interface InitialMenuState {
  items: MenuType[]
  nav: SecondNavMenuItemType[]
}

const initialMenuState: InitialMenuState = {
  items: [],
  nav: [],
}

export default useVenue
