import { DayPicker, DayPickerProps } from 'react-day-picker'
import { buttonVariants, cn, iconButtonVariants, SButton } from 'tailwind-ui'

import { ScrollArea } from '@/common/scroll-area'
import {
  eachMinuteOfInterval,
  endOfDay,
  format,
  isSameDay,
  startOfDay,
  subMinutes,
} from 'date-fns'

function Calendar({
  className,
  classNames,
  showTimes = false,

  ...props
}: DayPickerProps & { showTimes?: boolean }) {
  console.log({ props }, 'Calendar')
  return (
    <>
      <DayPicker
        className={cn(
          'self-start w-full flex-1 object-top',
          { 'pl-4 pr-2 pt-2': showTimes },
          className
        )}
        classNames={{
          // Merge the former "table" and "month_grid" keys:
          month_grid: cn('w-full border-collapse flex flex-col relative'),
          // "head_row" is replaced by "weekdays"
          months: 'relative',
          weekdays: 'head-stampede w-full flex justify-between px-4',
          // "head_cell" is replaced by "weekday" (merging with any weekday styling)
          weekday:
            'px-0 text-neutral-500 py-2 dark:text-neutral-600 rounded-md font-normal text-[0.8rem] text-center',
          // "cell" has been removed in favor of "day", so "row" is replaced with "week":
          weeks: 'row-stampede-weeks flex flex-col w-full',
          week: 'row-stampede grid grid-cols-7 w-full',

          // The day cell styling remains on "day"
          day: cn(
            'relative flex-1 w-full table-cell rounded-full text-center flex align-middle justify-center items-center',
            'size-full aspect-square p-0 text-sm',
            'aria-selected:font-bold',
            'aria-selected:bg-neutral-300 dark:aria-selected:bg-neutral-700',
            'focus-within:relative'
          ),

          // The button inside the day cell
          day_button: cn(
            buttonVariants({ variant: 'ghost_default' }),
            'inline-flex bg-transparent disabled:bg-transparent dark:disabled:bg-transparent items-center justify-center size-full p-0 font-normal leading-none'
          ),

          // "selected" remains the same
          selected: 'bg-black/20 dark:bg-white/20 ',

          // Merge "today" & "day_today" into the new "today" key
          today: cn(
            'stampede-today',
            'border-2 border-neutral-200 dark:border-neutral-600 dark:text-white/80'
          ),
          // "disabled" remains and "day_disabled" is removed
          disabled: 'text-neutral-200 dark:text-neutral-800',

          // Update deprecated day-range keys to new SelectionState keys
          range_start: cn('day-range-start !bg-black/20 dark:!bg-white/20', {
            //   'rounded-r-none1': props.mode === 'range' && !props.selected?.to,
            'rounded-r-none':
              props.mode === 'range' &&
              props.selected?.from &&
              props.selected?.to,
            'rounded-full':
              (props.mode === 'range' &&
                props.selected?.from &&
                !props.selected?.to) ||
              (props.mode === 'range' &&
                isSameDay(props.selected?.from, props.selected?.to)),
          }),
          range_middle: ' rounded-none',
          range_end: cn('day-range-end bg-black/20 dark:bg-white/20', {
            'rounded-l-none':
              props.mode === 'range' &&
              props.selected?.to &&
              props.selected?.from,
            'rounded-full':
              (props.mode === 'range' &&
                props.selected?.from &&
                !props.selected?.to) ||
              (props.mode === 'range' &&
                isSameDay(props.selected?.from, props.selected?.to)),
          }),

          // "day_hidden" is renamed to "hidden"
          hidden: 'invisible',

          // Navigation container remains the same
          nav: 'space-x-1 flex items-center absolute top-0 right-1',
          // Remove "nav_button" (deprecated) and update the individual buttons to:
          button_previous: cn(
            iconButtonVariants({ variant: 'ghost_default', size: 'sm' }),
            '!border-0'
          ),
          button_next: cn(
            iconButtonVariants({ variant: 'ghost_default', size: 'sm' }),
            '!border-0'
          ),

          // "caption" is replaced with "month_caption"
          month_caption: 'flex justify-between items-center  px-4',
          caption_label: 'text-sm font-medium',

          chevron: 'p-0 font-normal size-4 dark:fill-white',
          // Spread in any custom classes provided via props
          ...classNames,
          // "day_outside" is replaced with the new "outside" key
          outside: 'outside-day text-neutral-400 dark:text-neutral-500',
        }}
        {...props}
      />
      {showTimes && (
        <ScrollArea className="h-96 border-l relative">
          <div className="overflow-hidden sticky top-0 px-1 py-1">
            <p className="font-bold rounded-lg text-sm text-center bg-black/10 dark:bg-white/10 backdrop-blur-lg">
              Time
            </p>
          </div>
          <div className="flex flex-col">
            {eachMinuteOfInterval(
              {
                start: startOfDay(props.selected),
                end: endOfDay(props.selected),
              },
              { step: 30 }
            ).map((item) => (
              <SButton
                className={cn('!rounded-none !rounded-r-full', {
                  'bg-blue-900': props.selected?.getTime() === item.getTime(),
                })}
                variant={
                  props.selected?.getTime() === item.getTime()
                    ? 'primary'
                    : 'ghost_default'
                }
                isActive={props.selected?.getTime() === item.getTime()}
                key={item.getTime()}
                onClick={() => {
                  props.onSelect?.(item)
                }}
                disabled={subMinutes(item, 15).getTime() < new Date().getTime()}
              >
                {format(item, 'h:mm aaa')}
              </SButton>
            ))}
          </div>
        </ScrollArea>
      )}
    </>
  )
}

Calendar.displayName = 'Calendar'

export { Calendar }
