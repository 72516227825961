'use client'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import { SIconButton, cn, cx } from 'tailwind-ui'
import {
  ChevronDoubleDownIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/16/solid'
import { useInView } from 'react-intersection-observer'

function Drawer({
  open = true,
  setOpen,
  children,
  title = '',
  size = 'lg',
}: {
  open: boolean
  setOpen?: (isOpen: boolean) => void
  children: React.ReactNode

  title?: string
  size?: 'sm' | 'md' | 'lg'
}) {
  const sizeClass =
    size === 'sm'
      ? 'md:w-1/2 md:max-w-full lg:w-1/3 lg:max-w-1/3 xl:w-1/4 xl:max-w-1/4'
      : size === 'md'
        ? 'lg:w-1/2 lg:max-w-1/2'
        : 'lg:w-3/4 lg:max-w-3/4'
  const handleDrawerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const [inViewRef, isInView] = useInView()

  return (
    <Dialog
      open={open}
      onClose={setOpen ?? (() => false)}
      transition
      className="relative z-50"
    >
      <DialogBackdrop
        className="fixed inset-0 duration-300 ease-out data-[closed]:opacity-0 bg-white/50 dark:bg-black/50 backdrop-blur-sm"
        transition
      />

      <div className="fixed inset-0 overflow-hidden mt-4">
        <div className="absolute inset-0 overflow-hidden mt-4">
          <div
            className={cx(
              ' pointer-events-none lg:py-2 md:pl-2 fixed inset-y-0 right-0 flex w-full max-w-full ',
              sizeClass
            )}
          >
            <DialogPanel
              transition
              className={cn(
                'w-full overflow-hidden',
                'lg:border-l lg:border-t lg:border-b border-black/5 dark:border-white/5',
                'bg-neutral-100/90 dark:bg-neutral-900/90 backdrop-blur-xl',
                'flex flex-col pointer-events-auto shadow-md rounded-xl md:mr-2 duration-300 ease-out',
                'max-md:m-1', // Add this line for horizontal margins on mobile
                'data-[closed]:translate-y-full',
                'md:data-[closed]:translate-y-0 md:data-[closed]:translate-x-full'
              )}
              ref={inViewRef}
            >
              {(setOpen || title) && (
                <div className=" flex pr-4 pl-1  space-x-2 justify-start items-center border-b dark:border-neutral-500/20 ">
                  {setOpen && (
                    <div className="hidden max-md:flex">
                      <SIconButton
                        isRound={false}
                        icon={ChevronDoubleDownIcon}
                        variant="ghost_default"
                        type="button"
                        className="my-1 rounded-lg"
                        onClick={() => {
                          setOpen(false)
                        }}
                      />
                    </div>
                  )}
                  {setOpen && (
                    <div className=" hidden md:flex">
                      <SIconButton
                        isRound={false}
                        icon={ChevronDoubleRightIcon}
                        variant="ghost_default"
                        type="button"
                        className="my-1 rounded-lg"
                        onClick={() => {
                          setOpen(false)
                        }}
                      />
                    </div>
                  )}
                  {title && (
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold capitalize leading-6 text-gray-900 dark:text-gray-50 pr-2"
                    >
                      {title}
                    </DialogTitle>
                  )}
                </div>
              )}

              <div className="flex-1 overflow-x-scroll overflow-y-scroll">
                {children}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
export default Drawer
