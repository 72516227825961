import { useLocation, useParams } from 'react-router-dom'
import morpheusApi from '@/state/morpheus/morpheus.slice'

const useUser = () => {
  const { pathname } = useLocation()
  const { data } = morpheusApi.useGetUserQuery('me', {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    skip:
      (pathname.includes('oauth') && !pathname.includes('google')) ||
      pathname === '/code' ||
      pathname.includes('account-invite'),
  })

  return data
}

export const useUserRole = () => {
  const { org_id } = useParams<{ org_id: string }>()
  const uid = useUser()?.uid
  const { data } = morpheusApi.useGetOrganisationUserRoleQuery(
    {
      orgId: org_id!,
      uid,
    },
    {
      skip: !uid || !org_id,
    }
  )

  return data
}

export default useUser
