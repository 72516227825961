import flatten from 'lodash/flatten'

const getCssVariable = (name: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(name)
}

const backgroundColors = [
  'var(--stampede-500)',
  '#32D583',
  '#F97066',
  'var(--blue-900)',
]

const GetIndexInAlphabet = (char: string) =>
  char.toUpperCase().charCodeAt(0) - 65

export const getColorFromString = (value: string) => {
  if (!value) return backgroundColors[0]
  const onlyString = value.replace(/[0-9]/g, '')
  const first = onlyString.charAt(0)
  const alphabetIndex = GetIndexInAlphabet(first)
  const fillArray = flatten(Array(26).fill(backgroundColors)).slice(0, 26)
  return fillArray[alphabetIndex]
}

export default getCssVariable
