import type { GalleryGetResponse } from '@/connect-types/members/galleryGetResponse.type'
import { initPage } from '@/connect-types/pagination/pagination.type'
import type { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { toast } from 'sonner'
import type { ActionType } from 'typesafe-actions'
import { getType } from 'typesafe-actions'
import * as actions from './member.actions'

export type MemberAction = ActionType<typeof actions>

export interface StateType {
  isLoading: boolean
  isEmailSending: boolean
  gallery: GalleryGetResponse
}

export const initialState: StateType = {
  isEmailSending: false,
  isLoading: false,
  gallery: initPage,
}

const memberReducer: Reducer<StateType, MemberAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.addTagToImage): {
        const item = draft.gallery.body.find(
          (item) => item.id.toString() === action.payload.imageId
        )
        if (item) {
          item.labels.push(action.payload.label)
        }

        break
      }

      case getType(actions.removeTagToImage): {
        const item = draft.gallery.body.find(
          (item) => item.id.toString() === action.payload.imageId
        )
        if (item) {
          item.labels = item.labels.filter(
            (item) => item.id !== action.payload.label.id
          )
        }

        break
      }

      case getType(actions.membersGalleryPushContent): {
        draft.gallery.body.unshift(action.payload.item)
        draft.gallery.total += 1
        break
      }

      case getType(actions.membersGalleryContentRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.membersGalleryContentRequestSuccess): {
        draft.isLoading = false
        draft.gallery = action.payload.gallery

        break
      }

      case getType(actions.membersGalleryContentRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequest): {
        draft.isLoading = true
        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_SUCCESS
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequestSuccess): {
        draft.isLoading = false
        toast.success('Image deleted')
        const find = draft.gallery.body.findIndex(
          (image) => image.id === action.payload.id
        )
        draft.gallery.body.splice(find, 1)

        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_FAILURE
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.sendSupportEmailRequest): {
        draft.isEmailSending = true
        break
      }

      case getType(actions.sendSupportEmailRequestSuccess): {
        draft.isEmailSending = false
        toast.success('Cancel request is being processed')
        break
      }

      case getType(actions.sendSupportEmailRequestFailure): {
        draft.isEmailSending = false
        break
      }
    }
  })

export default memberReducer
