import { useEffect } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'

//console.log({ pwaInfo })
// 2 mins * 60 seconds * 1000 milliseconds = 300000
const intervalMS = 1 * 60 * 1000
const reloadSW = __RELOAD_SW__ as boolean
const toastId = 'reload-toast'

function ReloadPrompt() {
  const {
    //  offlineReady: [offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onOfflineReady: () => {
      console.log('offline-ready')
    },
    async onRegisteredSW(swUrl, r) {
      console.log('sw-registered', r, { reloadSW, swUrl })

      reloadSW &&
        r &&
        setInterval(async () => {
          try {
            if (r.installing || !navigator) return

            if ('connection' in navigator && !navigator.onLine) return

            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                'cache-control': 'no-cache',
              },
            })

            if (resp?.status === 200) {
              await r.update()
            }
          } catch (error) {
            console.error('Error checking for SW updates:', error)
          }
        }, intervalMS)
    },
    onNeedRefresh: () => {
      console.log('new content available')
    },
    onRegisterError(error) {
      console.error('SW registration error:', error)
    },
  })

  useEffect(() => {
    let inactivityTimeout: NodeJS.Timeout
    let lastActivity = Date.now()

    const resetInactivityTimer = () => {
      lastActivity = Date.now()
      clearTimeout(inactivityTimeout)
      const currentPath = window.location.pathname
      const splitPath = currentPath.split('/')
      if (needRefresh && splitPath.length <= 4) {
        inactivityTimeout = setTimeout(async () => {
          const timeSinceLastActivity = Date.now() - lastActivity
          if (timeSinceLastActivity >= intervalMS) {
            console.log('User inactive, applying update...')
            await updateServiceWorker()
          }
        }, intervalMS)
      }
    }

    // Track user activity
    const activityEvents = [
      'mousedown',
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
    ]

    activityEvents.forEach((event) => {
      window.addEventListener(event, resetInactivityTimer)
    })

    // Initial timer setup
    resetInactivityTimer()

    // Cleanup
    return () => {
      clearTimeout(inactivityTimeout)
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimer)
      })
    }
  }, [needRefresh, updateServiceWorker])

  return null
}

export default ReloadPrompt
