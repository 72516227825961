import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useEffect, useMemo, useState } from 'react'
import backendApi from '@/state/backend/backend.slice'
import type { RootNode, Segment } from '@/state/entities/segment/segment.types'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import useGlobal from './useGlobal'
import { useParams } from 'react-router-dom'
import { allSegment } from '@/state/entities/segment/segment.mock'

export const useSegmentMetadata = () => {
  const { orgId } = useGlobal()
  const { data: metadata = null } = morpheusApi.useGetSegmentMetadataQuery({
    orgId,
  })

  return metadata
}

export const useSegments = () => {
  const { org_id: orgId } = useParams<{ org_id: string }>()

  if (!orgId) {
    console.warn('useSegments called without orgId')
    return { data: [], isLoading: false, error: new Error('Missing orgId') }
  }

  const {
    data: segmentsData = [],
    isLoading: isSegmentsLoading,
    error: segmentsError,
    ...rest
  } = morpheusApi.useGetSegmentsQuery({ orgId })

  const { data: reachData, isLoading: isReachLoading } =
    morpheusApi.useGetReachQuery({ orgId })

  const isLoading = isSegmentsLoading || isReachLoading

  const segmentsIncludingAll = useMemo(() => {
    if (segmentsData.length === 0) return []
    if (segmentsData.length > 0 && !reachData) {
      return [...segmentsData].sort(
        (a, b) => b.reach.reach.all - a.reach.reach.all
      )
    }
    if (!segmentsData || !reachData) return []

    const enrichedSegments = [...segmentsData]

    enrichedSegments.unshift({
      ...allSegment,
      reach: {
        reach: reachData as Segment['reach']['reach'],
        version: 0,
      },
    })

    return enrichedSegments.sort(
      (a, b) => b.reach.reach.all - a.reach.reach.all
    )
  }, [segmentsData, reachData])

  return {
    data: segmentsIncludingAll,
    isLoading,
    error: segmentsError,
    ...rest,
  }
}

export const useSingleSegment = (id: string) => {
  const { orgId } = useGlobal()
  const { data: segments, ...rest } = useSegments()
  const data = morpheusApi.useGetSegmentQuery(
    {
      orgId,
      id,
    },
    {
      skip: !id || id === 'new',
    }
  )

  if (id === 'all' || !id) {
    return {
      data: segments.find((item) => item.id === 'all'),
      ...rest,
    }
  }

  return data
}
