import { useParams } from 'react-router-dom'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import morpheusApi from '@/state/morpheus/morpheus.slice'

export const useSelectedVenue = (serial = '') => {
  const { org_id } = useParams<{ org_id: string }>()
  const data = morpheusApi.useGetLocationQuery(
    { serial, orgId: org_id },
    { skip: !serial || serial === 'unlinked' || !org_id }
  )

  return data
}

export const useSelectedOrganisationVenue = (serial: string) => {
  const selectedOrganisation = useSelectedOrganisation()

  return (selectedOrganisation?.locations ?? []).find(
    (item) => item.serial === serial
  )
}
