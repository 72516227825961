/**
 * Performance optimization utilities
 * Provides functions to optimize resource loading and improve Core Web Vitals
 */

/**
 * Preloads critical resources needed for the current page
 * @param resources Array of URLs to preload with their types
 */
export const preloadCriticalResources = (
  resources: Array<{
    url: string
    type: 'script' | 'style' | 'image' | 'font' | 'fetch'
  }>
) => {
  // Skip if preload is not supported
  if (!('preload' in HTMLLinkElement.prototype)) return

  resources.forEach(({ url, type }) => {
    const link = document.createElement('link')
    link.rel = 'preload'
    link.href = url
    link.as = type

    // Add crossorigin for fonts
    if (type === 'font') {
      link.crossOrigin = 'anonymous'
    }

    // Add to document head
    document.head.appendChild(link)
  })
}

/**
 * Establishes early connections to important domains
 * @param domains Array of domains to connect to
 */
export const preconnectToDomains = (domains: string[]) => {
  domains.forEach((domain) => {
    // Preconnect - establish early connection
    const preconnect = document.createElement('link')
    preconnect.rel = 'preconnect'
    preconnect.href = domain
    preconnect.crossOrigin = 'anonymous'
    document.head.appendChild(preconnect)

    // DNS prefetch - as fallback for browsers that don't support preconnect
    const dnsPrefetch = document.createElement('link')
    dnsPrefetch.rel = 'dns-prefetch'
    dnsPrefetch.href = domain
    document.head.appendChild(dnsPrefetch)
  })
}

/**
 * Sets fetchPriority attribute on important elements
 * Helps browsers prioritize loading of critical resources
 */
export const setPriorityHints = () => {
  // Check if fetchPriority is supported (ignoring TypeScript errors)
  if (!('fetchPriority' in HTMLImageElement.prototype)) return

  // Set high priority on LCP candidate images
  const heroImages = document.querySelectorAll(
    '.hero-image, .banner-image, header img'
  )
  heroImages.forEach((img) => {
    if (img instanceof HTMLImageElement) {
      // @ts-ignore - fetchPriority is not in standard TS definitions yet
      img.fetchPriority = 'high'
    }
  })

  // Set low priority on offscreen or less important images
  const offscreenImages = document.querySelectorAll('.footer img, .lazy-load')
  offscreenImages.forEach((img) => {
    if (img instanceof HTMLImageElement) {
      // @ts-ignore - fetchPriority is not in standard TS definitions yet
      img.fetchPriority = 'low'
    }
  })
}

/**
 * Optimizes the Largest Contentful Paint by improving loading of hero images
 */
export const optimizeLCP = () => {
  // Find potential LCP elements
  const lcpCandidates = document.querySelectorAll(
    'img.hero-image, header img, .banner-image'
  )

  lcpCandidates.forEach((img) => {
    if (img instanceof HTMLImageElement) {
      // Set fetchPriority if supported
      if ('fetchPriority' in HTMLImageElement.prototype) {
        // @ts-ignore - fetchPriority is not in standard TS definitions yet
        img.fetchPriority = 'high'
      }

      // Set loading mode to eager for above-the-fold images
      img.loading = 'eager'

      // Add decoding async for better performance
      img.decoding = 'async'
    }
  })
}

/**
 * Initializes all performance optimizations
 * Call this early in your application load
 */
export const initializePerformanceOptimizations = () => {
  // Preconnect to critical domains
  preconnectToDomains([
    'https://fonts.googleapis.com',
    'https://fonts.gstatic.com',
    'https://static.zdassets.com',
    'https://o142237.ingest.sentry.io',
  ])

  // Set priority hints after DOM is loaded
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', setPriorityHints)
  } else {
    setPriorityHints()
  }

  // Optimize LCP after images are available
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', optimizeLCP)
  } else {
    optimizeLCP()
  }
}

/**
 * Reports Core Web Vitals to console (in development) or to your analytics
 * This is already handled by our Sentry integration, but this provides additional logging
 */
export const logCoreWebVitals = (metric: any) => {
  // Create a more readable format of the metric
  const value = Math.round(metric.value * 10) / 10 // Round to 1 decimal place

  // Add color coding for console logs
  let color = 'green'
  if (metric.rating === 'needs-improvement') color = 'orange'
  if (metric.rating === 'poor') color = 'red'

  // Log with nice formatting in development
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `%c${metric.name}: %c${value} %c(${metric.rating})`,
      'font-weight: bold;',
      `color: ${color}; font-weight: bold;`,
      'font-weight: normal;'
    )
  }
}
