import { newRoutesArray } from '@/paths'
import * as Sentry from '@sentry/react'
import { createElement, memo, Suspense, useEffect, useState } from 'react'
import type { RouteObject } from 'react-router-dom'
import {
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from 'react-router-dom'
import { ContentContainer, SButton, SNavLink, Spinner } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import type { NormalRoutesType } from './types'
import { isInStandaloneMode } from './utils/helpers/pwa'
import BubbleLoad from './ux/bubble-load'

export function ErrorBoundary() {
  const error = useRouteError()
  const location = useLocation()
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  // Monitor online/offline status
  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  const isChunkError =
    error.toString().includes('ChunkLoad') ||
    error.toString().includes('Loading CSS chunk') ||
    error.toString().includes('Failed to fetch dynamically imported module') ||
    error.toString().includes(`'text/html' is not a valid JavaScript MIME`)

  // Handle offline state
  if (!isOnline) {
    return (
      <ContentContainer className="py-4 space-y-4">
        <div>
          <Heading>You're offline</Heading>
          <Description>
            Please check your internet connection and try again when you're back
            online.
          </Description>
        </div>
        <div className="rounded-full gap-1 flex p-1">
          <SButton
            onClick={() => {
              window.location.reload()
            }}
          >
            Try Again
          </SButton>
        </div>
      </ContentContainer>
    )
  }

  if (isRouteErrorResponse(error) && error.status === 401) {
    // the response json is automatically parsed to
    // `error.data`, you also have access to the status
    return (
      <div>
        <h1>{error.status}</h1>
        <h2>{error.data.sorry}</h2>
        <p>{error.data.hrEmail} if this is a mistake.</p>
      </div>
    )
  }

  //if (isRouteErrorResponse(error) && error.status === 404) {

  if (isChunkError) {
    window.location.reload()
    return <BubbleLoad text="Reloading page..." />
  }
  Sentry.captureException(error)

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  return (
    <ContentContainer className="py-4 space-y-4">
      <div>
        <Heading>Something went wrong</Heading>
        <Description>
          Something went wrong loading this section. Please try reloading the
          page
        </Description>
      </div>
      {import.meta.env.DEV && (
        <div className="h-full rounded-xl gap-1 flex p-4 bg-red-500 border border-black/10 dark:border-white/10 flex-col">
          <p className="text-lg">{error.toString()}</p>
          <code className="text-xs">
            <pre>{JSON.stringify(location, null, 2)}</pre>
          </code>
        </div>
      )}
      <div className="rounded-full gap-1 flex p-1">
        <SButton
          onClick={() => {
            window.location.reload()
          }}
        >
          Reload Page
        </SButton>

        {isInStandaloneMode() ? (
          <SButton
            variant="ghost_default"
            onClick={() => {
              navigator.serviceWorker
                .getRegistrations()
                .then(async (registrations) => {
                  await registrations.forEach((registration) =>
                    registration.unregister()
                  )
                  window.location.reload()
                })
            }}
          >
            Reload App
          </SButton>
        ) : (
          <SNavLink to="/" variant="ghost_default">
            Reload App
          </SNavLink>
        )}
      </div>
    </ContentContainer>
  )
}

const RoutesToItem = (routes: NormalRoutesType[]): RouteObject[] => {
  return routes.map((route): RouteObject => {
    const routeObject: RouteObject = {
      path: route.path,
      errorElement: <ErrorBoundary />,
      index: route.index,
    }

    if (route.component) {
      if (typeof route.component === 'string') {
        console.warn('String components are not supported:', route.component)
      } else if (route.component.$$typeof.toString() === 'Symbol(react.lazy)') {
        routeObject.element = (
          <Suspense fallback={<BubbleLoad text="Loading..." />}>
            {createElement(route.component)}
          </Suspense>
        )
      } else {
        routeObject.element = route.component
      }
    }

    // Handle nested routes recursively
    if (route.children && route.children.length > 0) {
      routeObject.children = RoutesToItem(route.children)
    }

    // Handle any additional route metadata/handles
    if (route.handle) {
      routeObject.handle = route.handle
    }

    return routeObject
  })
}

export const routes = RoutesToItem(newRoutesArray)
