import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface HistoryContextType {
  history: string[]
}

const HistoryContext = createContext<HistoryContextType>({ history: [] })

export const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation()
  const [history, setHistory] = useState<string[]>([])

  useEffect(() => {
    setHistory((prev) => {
      // Only add the new location if it’s different from the last recorded one.
      if (prev[prev.length - 1] !== location.pathname) {
        return [...prev, location.pathname]
      }
      return prev
    })
  }, [location.pathname])

  return (
    <HistoryContext.Provider value={{ history }}>
      {children}
    </HistoryContext.Provider>
  )
}

export const useHistoryContext = () => useContext(HistoryContext)
