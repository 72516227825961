import { Base64 } from 'js-base64'

interface OptionWidths {
  width: number
  height: number
}

interface Options {
  mobile: OptionWidths
  desktop: OptionWidths
  kind: 'png' | 'jpg'
  gravity: 'ce' | 'sm' | 'no'
  resize: 'fit' | 'fill' | 'crop' | 'force'
}

export interface ImageTypes {
  header: Options
  background: Options
  story: Options
  tiny: Options
  gallery: Options
  avatar: Options
  galleryList: Options
  LoyaltyIcon: Options
  thumbnail: Options
  tile: Options
}

const dimensions = (width: number, height: number): OptionWidths => ({
  height,
  width,
})

const options: ImageTypes = {
  header: {
    mobile: dimensions(300, 300),
    desktop: dimensions(330, 330),
    kind: 'png',
    gravity: 'ce',
    resize: 'fit',
  },
  background: {
    mobile: dimensions(1000, 1000),
    desktop: dimensions(1000, 1000),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  story: {
    mobile: dimensions(540, 960),
    desktop: dimensions(540, 960),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  tiny: {
    mobile: dimensions(32, 32),
    desktop: dimensions(32, 32),
    kind: 'png',
    gravity: 'ce',
    resize: 'fill',
  },
  gallery: {
    mobile: dimensions(300, 300),
    desktop: dimensions(300, 300),
    kind: 'png',
    gravity: 'ce',
    resize: 'fill',
  },
  galleryList: {
    mobile: dimensions(100, 50),
    desktop: dimensions(100, 50),
    kind: 'png',
    gravity: 'ce',
    resize: 'fill',
  },
  avatar: {
    mobile: dimensions(300, 300),
    desktop: dimensions(300, 300),
    kind: 'png',
    gravity: 'ce',
    resize: 'fill',
  },
  LoyaltyIcon: {
    mobile: dimensions(50, 50),
    desktop: dimensions(50, 50),
    kind: 'png',
    gravity: 'ce',
    resize: 'fill',
  },
  thumbnail: {
    mobile: dimensions(200, 200),
    desktop: dimensions(200, 200),
    kind: 'png',
    gravity: 'no',
    resize: 'fill',
  },
  tile: {
    mobile: dimensions(400, 400),
    desktop: dimensions(600, 600),
    kind: 'png',
    gravity: 'no',
    resize: 'fill',
  },
}

const isRetina = (): boolean => {
  const mediaQuery =
    '(-webkit-min-device-pixel-ratio: 1.5),(min--moz-device-pixel-ratio: 1.5),(-o-min-device-pixel-ratio: 3/2),(min-resolution: 1.5dppx)'
  if (window.devicePixelRatio > 1) return true
  if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true
  return false
}

const isMobile = (): boolean => {
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    // true for mobile device
    return true
  }
  return false
}

const baseUrl = 'https://image.stampede.ai'

//const gravity = 'ce'
const enlarge = 1

export const generateImage = (
  filepath: string,
  type: keyof ImageTypes,
  ignoreMobile?: boolean //don't reorientate for mobile
): string => {
  if (typeof filepath !== 'string') return ''
  const path = Base64.encode(filepath, true)
  const mutiplier = isRetina() ? 2 : 1
  const kind = isMobile() && !ignoreMobile ? 'mobile' : 'desktop'
  const option = options[type]
  const url = `${baseUrl}/${option.resize}/${option[kind].width * mutiplier}/${
    option[kind].height * mutiplier
  }/${option.gravity}/${enlarge}/${path}.${option.kind}`
  return url
  //https://wifi.stampede.ai/images/fill/1400/1000/sm/0/plain/http://s3-eu-west-1.amazonaws.com/nearly.online/static/media/branding/BBDBNFDEYRJE/fle_4f0e8bffbe63c6d6446dbe2285637f08.png@jpg
}
