import { DefaultAnalyticsRequest } from '@/connect-types/analytics/request.type'
import { OrganisationLocationType } from '@/connect-types/organisations/organisations.type'
import { createAction, createAsyncAction } from 'typesafe-actions'
import { DefaultQueryType } from './global.types'

/* Test global action: open/close info banner */

/**
 * Info banner
 */

export const setQuery = createAction(
  'GLOBAL_SET_QUERY',
  (resolve) => (query: Partial<DefaultQueryType>) => resolve({ query })
)

export const clearAll = createAction(
  'GLOBAL_CLEAR_ALL',
  (resolve) => () => resolve({})
)

export const setListStyle = createAction(
  'GLOBAL_LIST_STYLE',
  (resolve) => (style: 'list' | 'grid') => resolve(style)
)

export const setMimicBanner = createAction(
  'GLOBAL_MIMIC_BANNER',
  (resolve) => (mimicBanner: boolean) => resolve({ mimicBanner })
)

export const setSelectedReviewPageId = createAction(
  'GLOBAL_SET_SELECTED_REVIEW_PAGE_ID',
  (resolve) => (id: string) => resolve({ id })
)

export const setOrgId = createAction(
  'GLOBAL_SET_ORG_ID',
  (resolve) => (orgId: string) => resolve({ orgId })
)

export const setDemoMode = createAction(
  'GLOBAL_SET_DEMO_MODE',
  (resolve) => (isDemoMode: boolean) => resolve({ isDemoMode })
)

export const openInfoBanner = createAction(
  'GLOBAL_OPEN_INFO_BANNER',
  (resolve) => () => resolve()
)

export const closeInfoBanner = createAction(
  'GLOBAL_CLOSE_INFO_BANNER',
  (resolve) => () => resolve()
)

export const toggleFilter = createAction(
  'GLOBAL_TOGGLE_FILTER',
  (resolve) => () => resolve()
)

export const locationModalSwitch = createAction(
  'LOCATION_MODAL_SWITCH',
  (resolve) => (visible: boolean) => resolve({ visible })
)

export const organisationModal = createAction(
  'ORGANISATION_MODAL_SWITCH',
  (resolve) => (visible: boolean) => resolve({ visible })
)

export const addLocationModalSwitch = createAction(
  'ADD_LOCATION_MODAL_SWITCH',
  (resolve) => (visible: boolean) => resolve({ visible })
)

export const setChartStyle = createAction(
  'SET_CHART_STYLE',
  (resolve) => (style: string) => resolve({ style })
)

export const setChartCompound = createAction(
  'SET_CHART_COMPOUND',
  (resolve) => (isCompound: boolean) => resolve({ isCompound })
)

export const openMenu = createAction(
  'GLOBAL_OPEN_MENU',
  (resolve) => () => resolve()
)

export const closeMenu = createAction(
  'GLOBAL_CLOSE_MENU',
  (resolve) => () => resolve()
)

export const clearSelectedLocation = createAction(
  'GLOBAL_CLEAR_SELECTED_LOCATION',
  (resolve) => () => resolve({})
)

export const updateLocations = createAction(
  'GLOBAL_UPDATE_LOCATIONS',
  (resolve) => (location: OrganisationLocationType) => resolve({ location })
)

export const setNotificationToken = createAction(
  'SET_NOTIFICATION_TOKEN',
  (resolve) => (token: string) => resolve({ token })
)

/**
 * Analytics payload
 */

export const setAnalyticsPayload = createAction(
  'GLOBAL_SET_ANALYTICS_PAYLOAD',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const setSelectedPartnerOrg = createAction(
  'SET_SELECTED_PARTNER_ORG',
  (resolve) => (selectedPartnerOrg: string) => resolve({ selectedPartnerOrg })
)

export const setInitialSearch = createAction(
  'SET_INITIAL_SEARCH',
  (resolve) => (term: string) => resolve({ term })
)

export const unlinkVenueFromOrg = createAsyncAction(
  'UNLINK_VENUE_FROM_ORG_REQUEST',
  'UNLINK_VENUE_FROM_ORG_SUCCESS',
  'UNLINK_VENUE_FROM_ORG_FAILURE'
)<
  {
    serial: string
    orgId: string
  },
  { status: number; message: string },
  Error
>()
