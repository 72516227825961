import { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { cn } from 'tailwind-ui'

interface FieldGroupProps {
  title?: string
  description?: string | ReactElement
  extra?: ReactElement
  isOnlyColumn?: boolean
  wrapTitle?: boolean
  className?: string
}

const FieldGroup: FunctionComponent<PropsWithChildren<FieldGroupProps>> = (
  props
) => {
  const {
    title,
    description,
    children,
    extra,
    isOnlyColumn,
    wrapTitle = false,
    className,
  } = props

  return (
    <div
      className={cn(
        'field-group py-6 flex flex-col md:gap-6 gap-6 ',
        isOnlyColumn ? 'md:flex-col' : 'md:flex-row',
        className
      )}
    >
      {/* Left column - Title and description */}
      <div className="min-w-[16rem] w-full flex-1">
        {title && (
          <div className="flex justify-between items-center">
            <Heading>{title}</Heading>
            {extra}
          </div>
        )}
        {description && (
          <Description className="text-neutral-800 dark:text-neutral-300 text-sm">
            {description}
          </Description>
        )}
      </div>

      {/* Right column - Form fields */}
      <div className="w-full flex-2 flex flex-col border border-black/10 dark:border-white/10 rounded-xl bg-neutral-50/90 dark:bg-neutral-950/90 divide-y divide-black/10 dark:divide-white/10">
        {children}
      </div>
    </div>
  )
}

export default FieldGroup
