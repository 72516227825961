import orderBy from 'lodash/orderBy'
import { type CSSProperties, useMemo } from 'react'
import { GridMultiSelect, Select } from 'tailwind-ui'
import useLocations from '@/state/entities/locations/hooks/useLocation'
import { type OrganisationLocationType } from '@/connect-types/organisations/organisations.type'

interface PassedProps {
  value: string | string[]
  onChange: (value: string | string[]) => void
  mode?: 'multiple' | 'tags'
  style?: CSSProperties
  reset?: boolean
  resetText?: string
  size?: 'sm' | 'md' | 'lg'
  altLocations?: OrganisationLocationType[]
  disabledLocations?: string[]
}

function LocationSelect({
  value,
  onChange,
  mode,
  size,
  reset,
  resetText,
  altLocations,
  disabledLocations,
}: PassedProps) {
  const locations = useLocations()

  const options = useMemo(
    () => orderBy(altLocations || locations, ['alias'], ['asc']),
    [locations, altLocations]
  )

  const selectOpts = [
    {
      value: null,
      label: resetText ?? 'No venue selected',
    },
    ...options.map(({ serial, alias }) => ({
      label: alias || serial,
      value: serial,
    })),
  ]

  if (mode === 'tags') {
    return (
      <Select
        style={{ width: '100%' }}
        value={value}
        variant="flushed"
        onChange={({ target }) => {
          if (target.value === resetText || !target.value) {
            onChange(null)
            return
          }
          onChange(target.value)
        }}
      >
        {reset ? (
          <option value={undefined}>
            {resetText ? resetText : 'No venue selected'}
          </option>
        ) : null}
        {options.map(({ alias, serial }) => (
          <option
            disabled={disabledLocations?.includes(serial)}
            value={serial}
            key={serial}
          >
            {alias || serial}
          </option>
        ))}
      </Select>
    )
  }

  const selValue = useMemo(() => {
    if (!value) return []
    const items = selectOpts
      .filter((item) => value.includes(item.value!))
      .map((s) => s.value)
    if (items.length === 0) {
      return []
    }
    return items
  }, [selectOpts, value])

  return (
    <GridMultiSelect
      options={selectOpts.map((selectOpt) => {
        return { value: selectOpt.value, name: selectOpt.label }
      })}
      selected={selValue}
      onChange={(v) => {
        console.log({ v })
        onChange(v ? (v.map((item) => item) as string[]) : [])
      }}
      placeholder="Select venues..."
      title="Venues"
      classNames={{
        selected: '',
        list_item:
          '!bg-neutral-400/10 !text-black dark:bg-black/10 dark:!text-white',
      }}
    />
    // <BookingSelect
    //   isMultiple
    //   value={selValue}
    //   onChange={(v) => {
    //     onChange(v ? (v.map((item) => item.value) as string[]) : [])
    //   }}
    //   placeholder={resetText}
    //   options={selectOpts}
    // />
  )
}

export default LocationSelect
